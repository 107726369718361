import React from 'react'
import { Link } from 'react-router-dom'
import { firebase, db } from './firebase/Firebase'
import ColType from './Types'
import { Steps, Tooltip, notification } from 'antd'
import moment from 'moment-timezone'
const Step = Steps.Step
const LZString = require('lz-string')

export const DEV_CLEVER_CLIENT_ID = 'cb50397bb4993dbfe2a1'
const PROD_CLEVER_CLIENT_ID = '4dc41b9f350383e59d31'
export const CLEVER_CLIENT_ID = PROD_CLEVER_CLIENT_ID
export const CLEVER_REDIRECT_URI = 'https://my.dotit.app/sign-in-clever'
export const DEV_CLEVER_REDIRECT_URI = 'https://my.dotit.app/beta-sign-in-clever'

export const FOLDER_CURRENT = 'current'
export const FOLDER_TRANSFER = 'transfer'
export const FOLDER_SHARED = 'shared'
export const FOLDER_ARCHIVED = 'archived'
export const FOLDER_TRASHBIN = 'trashbin'
export const FOLDER_PERMANENTLY_DELETED = 'permanently_deleted'

// only folders that can be accessed by teachers
export const FOLDER_TO_COLTYPE_OBJ = {
  [FOLDER_ARCHIVED]: ColType.archivedStudents,
  [FOLDER_TRANSFER]: ColType.studentTransfers,
  [FOLDER_TRASHBIN]: ColType.deletedStudents,
  [FOLDER_PERMANENTLY_DELETED]: ColType.permanentlyDeletedStudents,
}

export const keywords = {
  'appropriately paced instruction': 'Appropriately paced instruction is the amount of time a student needs to learn the content. The best way to describe it is "as fast as they can, as slow as they need." A good rule of thumb for students with disabilities is at least a 10 second wait time before interrupting thinking.',
  'clear verbal instruction': 'Clear verbal instruction is when the teacher tells the student 4 things.  First, the teacher states the purpose of the activity. Second, the teacher states whether the activity is a solo or group one. Third, the teacher describes what the activity is and the resources or materials to complete it. Fourth, the teacher states the amount of time allotted for the task.',
  'visually supported instruction': 'Visually supported instruction is a way to leverage strengths for students with poor verbal skills.  Visual supports can be pictures, images, or tangible objects.',
  'step-by-step instruction': 'Step by step instruction is systematic and cumulative.  Simplest concepts are taught first, followed by more complicated concepts. Explicit guidance is given for how to solve novel problems that the student has never encountered before.',
  'multisensory instruction': 'Multisensory instruction is a way of teaching that engages more than one sense at a time. Using sight, hearing, movement, and touch gives students more than one way to connect with what they are learning.',
  'working memory': 'A working memory challenge impacts the ability to hold new information and use it to successfully complete a task. These students have a difficult time remembering directions, recalling facts, maintaining attention, and completing assignments. Working memory distributes information in the brain so it also impacts the other cognitive skills. That\'s why students with weak working memory usually need the most support.',
  'presentation strategies': 'Strategies to represent content in multiple ways to enhance understanding. Universal Design for Learning calls it multiple means of representation or the "what" of learning.',
  'preview the lesson': 'The teacher reviews the lesson objectives with examples and non-examples.',
  'connect new concepts to prior knowledge': 'The teacher connects new learning to a student\'s existing prior knowledge. By doing so, the student can build on what is already there forming something like a bridge between the old and the new. That bridge, or connection, makes learning easier.',
  'repeat instructions': 'Teacher says directions again.',
  'provide anchor charts': 'Poster with information to support previous lessons and/or reminder of key concepts.',
  'start each lesson with a review of the previous one': 'Teacher selects key concepts or key words to go over from past lessons before starting the new lesson.',
  'paraphrase what has been taught periodically during lesson': 'Teacher frequently restates the concepts taught.',
  'use of mnemonics': 'Memory aids to support remembering. For example, remember this sentence to spell "said"- Snakes and insects dance.',
  'engagement strategies': 'Strategies to increase student motivation and excitement about learning. Universal Design for Learning calls it multiple means of engagement or the "why" of learning.',
  'have the student verbalize the task': 'The student explains in own words what the assignment means.',
  'provide multiple opportunities for practice in short sessions': 'The teacher gives the student a chance to improve performance by repeating tasks and giving them feedback within a brief timeframe.',
  'use peer buddy to review lessons': 'A peer buddy is a student who is selected by the teacher based on knowledge of the subject and comfort level with providing support to another student.',
  'create a safe environment for asking for help when unclear about directions or concepts': 'The teacher creates a safe environment by ensuring the classroom is clean, orderly, and inviting. Organize the physical space of your classroom for movement and interaction with resources readily available. Create a predictable daily routine supported by pictures/words that is reviewed every day. No matter what a student says, make it a habit to respond with respect.',
  'visual cues to maintain attention': 'Visual cues are symbols that provide a student with information about how to maintain attention. Examples are drawing arrows to important information and/or drawing boxes around specific sections of the task.',
  'color code key words': 'The teacher highlights key vocabulary to enhance understanding.',
  'provide immediate positive feedback for effort and persistence': 'The teacher specifically recognizes an action or behavior by the student as soon as it occurs. By doing so, a student remains more motivated to finish a task.',
  'expression strategies': 'Strategies to provide different ways for students to express what they learn. Universal Design for Learning calls it multiple means of expression or the "how" of learning.',
  'create consistent daily class routines': 'The teacher takes the time to model and teach procedures and routines. By doing so, a student has more cognitive space to express his/her learning.',
  'provide opportunities for repeating a task when needed': 'The teacher allows a student another chance to show his/her knowledge.',
  'use of physical movement and/or songs to teach new concepts': 'Movement and song can provide a student with additional cues and create a deeper memory in order to express his/her learning. Most famous example is the ABC song.',
  'provide visuals to accompany instruction': 'The teacher gives the student concrete representations of information that is absorbed visually along with instruction. For example, picture cues, objects, or photos.Through visual supports, a student can more easily comprehend what the expectation is of what he/she needs to express to show learning.',
  'access to tools that facilitate learning (calculator/spellcheck)': 'The student is given access to any electronic, digital or physical tool that support his or her ability to perform tasks or generate a product.',
  'verbal comprehension': 'A verbal comprehension challenge impacts the ability to understand information presented verbally, determine the meaning of new words and concepts, and to communicate thoughts and ideas.',
  'preteach new vocabulary': 'The teacher previews the lesson in order to select words to teach before the lesson begins. The words selected are based on their importance for understanding the lesson objectives.',
  'highlight key words and major concepts': 'The teacher color codes important words and concepts that are essential for understanding.',
  'provide visuals or graphics to support new concepts': 'The teacher gives the student concrete representations of information that is absorbed visually along with instruction. For example, picture cues, objects, or photos.Through visuals or graphics, a student can more easily comprehend what the expectation is of what he/she needs to learning.',
  'proximity seating to support verbal comprehension': 'The student sits near the teacher.',
  'provide a fill in the blank outline or cloze notes': 'A type of outline or notes with one or more words replaced with a blank line. The student then adds the missing words.',
  'paraphrase what has been taught periodically during the lesson': 'The teacher frequently restates the concepts taught.',
  'incorporate interests and/or prior knowledge when teaching new concepts': 'Prior knowledge is the collection of memories, experiences, and understandings a student holds, which create a foundational context for new learning. Combining student interest and prior knowledge is a powerful engagement strategy.',
  'frequently check in with student to ensure comprehension': 'The teacher continually verifies that the student is learning what is being taught. Here are some ways to check for understanding: ask open-ended questions, thumbs-up, exit tickets, and four-finger rating.',
  'provide a reward system': "A form of extrinsic motivation to encourage a student to participate in academic and/or social learning activities. Rewards celebrate a student's achievement in a positive way.",
  'use of graphic organizers to teach new concepts': 'Graphic organizers are a visual way to organize informations. Different types of graphic organizers include: Venn diagrams, concept maps, story maps and concept diagram. By organizing information in a visual way, students can better express their learning.',
  'provide a word bank': 'A Word Bank is a written list of key vocabulary words or phrases. Pictures and/or photos can be added next to the words for additional cues.',
  'use of anchor charts': 'An anchor chart is a poster created to display important information found in daily lessons. The student can use anchor charts to help express his/her learning.',
  'use of word walls': 'A word wall is a collection of specific words displayed on a bulletin board. A student can use the word wall to help express his/her thoughts when completing tasks.',
  'offer options to written assignments': 'A student is given a choice of expressing his/her learning in a different way instead of writing. Some options include orally answering questions, competing a graphic organizer, or creating a powerpoint.',
  'fluid reasoning': 'These students have difficulty applying ideas and information in new situations. They have challenges solving problems for which they have little experience or prior knowledge. They struggle to find patterns and relationships and have difficulty with inferencing.',
  'provide teacher think aloud when teaching new concepts': "The teacher talks about his/her internal thinking in relation to the task. This gives the student an example of the teacher's cognitive process used to understand a new concept.",
  'supply an outline of lessons': 'Bulleted notes that highlight key points.',
  'use numerous examples to explain new concepts': 'The teacher gives multiple examples (ways to explain an idea or concept).',
  'preview tasks and expectations': 'The teacher prepares the student for the task before it begins. The teacher may ask questions such as: "How is it organized?" "What do you think it is about?" What\'s important?"',
  'use graphic organizers to provide a summary of information': 'Graphic organizers are key to helping a student organize, clarify, or simplify complex information in order to express his/her learning. Some examples of graphic organizers are knowledge map, concept map, story map, or concept diagram.',
  'give analogies that are relatable': 'The teacher describes how two concepts are similar.',
  'provide time for student to explain their work': 'The student explains in own words what the assignment means.',
  'designate a peer buddy to collaborate on task': 'A peer buddy is a student who is selected by the teacher based on knowledge of the subject and comfort level with providing support to another student.',
  'provide time for feedback on student work.': "The teacher gives a verbal or written response about the student's performance or behavior.",
  'provide exemplars': 'The teacher gives samples of correct student work. By doing so, a student is able to understand what the end product should look like.',
  'provide procedural checklists': 'Procedural checklists are a list of steps to be completed in order. Checklists help a student manage a task and complete it.',
  'provide visual schedules': 'The teacher provides a visual schedule of the day using icons, photos, or picture symbols. When a student does not have to guess what is happening next, he/she has more cognitive space to express his/her learning.',
  'processing speed': 'Students have difficulty processing information quickly and efficiently. These students have difficulty learning the same amount of material in the same amount of time as their peers.',
  'break the lesson into smaller chunks': 'The teacher determines how to break the lesson into smaller parts so the student can absorb it.',
  'talk slowly when giving oral directions': 'The teacher talks with the student about the rate of the instruction that works for him/her.',
  'give simple written directions': 'When giving directions, the teacher uses direct and clear language with few words.',
  'listen to an audio version when reading or instead of reading': 'The teacher allows audio of the text to pair with the lesson.',
  'provide a hook related to personal interests for lengthy assignments': "The teacher gets the student's attention by giving an interesting reason to pay attention. The reason is based on student interest.",
  'check for understanding during each lesson': 'The teacher verifies that the student is learning what is being taught. Here are some ways to check for understanding: ask open-ended questions, thumbs-up, exit tickets, and four-finger rating.',
  'create a signal with the student to encourage participation': 'The teacher and the student agree upon an appropriate sign to help student engagement.',
  'provide extra response time for answering questions': 'The teacher allows more time for a student to respond.',
  'use of a highlighter or sticky notes': 'The student color codes important words and/or adds sticky notes to summarize important information.',
  'provide notes or lesson outlines': 'Bulleted notes that highlight key points.',
  'provide alternatives for lengthy handwritten assignments': 'A student is given a choice of expressing his/her learning in a different way instead of writing. Some options include orally answering questions, competing a graphic organizer, or creating a powerpoint.',
  'show an exemplar before the start of the task': 'The teacher gives samples of correct student work. By doing so, a student is able to understand what the end product should look like.',
  'provide extended time to complete assignments': 'The teacher allows the student more time to finish tasks with no consequence.',
  'provide a rubric about how the task will be graded': 'A rubric is a specific set of criteria used for grading tasks.',
  'give access to tools that support processing (calculator/spell check)': 'The student is given access to any electronic, digital or physical tool that support his or her ability to perform tasks or generate a product.',
}

export function isReadOnlyTeacherId(teacherId) {
  return teacherId === "HtVCfM1gfSFtbGo0Kdp3"
}

export function linkAfterLogin(userInfo, userJobInfo, queryString = '') {
  if (userInfo.accessLevel === "admins") {
    window.location.href = "/admin/pacing-guide/guide/" + userJobInfo.id + queryString
  }
  if (userInfo.accessLevel === 'instructionalFacilitators') {
    window.location.href = "/instructional-facilitator/pacing-guide/guide/" +
      userJobInfo.id + queryString
  }
  if (userInfo.accessLevel === "schoolAdmins") {
    window.location.href = "/school-admin/pacing-guide/guide/" + userJobInfo.id + queryString
  }
  if (userInfo.accessLevel === "teachers") {
    if (userJobInfo.hasOwnProperty('isCustomForm') &&
      userJobInfo.isCustomForm &&
      userJobInfo.isCustomForm === 'california') {
      window.location.href = "/teacher/california-form/" + userJobInfo.id + queryString
    } else {
      window.location.href = "/teacher/pacing-guide/guide/" + userJobInfo.id + queryString
    }
  }
  if (userInfo.accessLevel === 'students') {
    window.location.href = "/student/quizzes" + queryString
  }
}

export function changeIEPGoalsFolder(student, totalIEPGoals, IEPGoalsToTransfer, folderFrom, folderTo, isNotStudent) {
  if (!(student && totalIEPGoals && IEPGoalsToTransfer && folderFrom && folderTo)) {
    return new Promise((resolve, reject) => {
      reject("Parameters are null")
    })
  }
  /*
  if (IEPGoalsToTransfer.length === 0) {
    return new Promise((resolve, reject) => {
      reject("No IEP goals to transfer to another folder")
    })
  }
   */

  let changeFolderPromises = []
  // see if there are no more goals in the from folder after the transfer is complete.
  // if so, delete this folder.
  // do not need to do this for "current" as there is no "current" folder
  if (folderFrom !== FOLDER_CURRENT &&
    FOLDER_TO_COLTYPE_OBJ.hasOwnProperty(folderFrom) &&
    totalIEPGoals.length === IEPGoalsToTransfer.length) {
    let folderFromColType = FOLDER_TO_COLTYPE_OBJ[folderFrom]

    // delete folder
    changeFolderPromises.push(
      new Promise((resolve, reject) => {
        db.collection(folderFromColType)
          .where('studentId', '==', student.id)
          .where('teacherId', '==', student.teacherId)
          .where('schoolId', '==', student.schoolId)
          .where('districtId', '==', student.districtId)
          .get()
          .then(querySnapshot => {
            if (!querySnapshot || (querySnapshot && querySnapshot.empty)) {
              // no folder to delete
              resolve()
            }
            else {
              // should always be > 0 if the snapshot is not empty, checking anyway
              // because of the [0] below. Only one folder per student should be created. 
              if (querySnapshot.docs.length > 0) {
                // check to see if there is only one folder, but allow continuing if there
                // are more than one.
                if (querySnapshot.docs.length !== 1) {
                  console.log("changeFolder expected 1 folder, found " + querySnapshot.docs.length + " folders.")
                }

                querySnapshot.docs[0].ref.delete()
                  .then(() => {
                    resolve()
                  })
                  .catch(err => {
                    console.log("changeFolder delete error: " + err)
                    reject(err)
                  })
              } else {
                // no folder to delete
                resolve()
              }
            }
          })
      })
    )
  }
  // create the to folder if it does not exist
  // do not need to do this for "current" as there is no "current" folder
  if (folderTo !== FOLDER_CURRENT && FOLDER_TO_COLTYPE_OBJ.hasOwnProperty(folderTo)) {
    let studentFolderColType = FOLDER_TO_COLTYPE_OBJ[folderTo]

    changeFolderPromises.push(new Promise((resolve, reject) => {
      db.collection(studentFolderColType)
        .where('studentId', '==', student.id)
        .where('teacherId', '==', student.teacherId)
        .where('schoolId', '==', student.schoolId)
        .where('districtId', '==', student.districtId)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot || (querySnapshot && querySnapshot.empty)) {
            db.collection(studentFolderColType)
              .add({
                studentId: student.id,
                teacherId: student.teacherId,
                schoolId: student.schoolId,
                districtId: student.districtId,
                latestUpdateTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(() => {
                resolve()
              })
              .catch(err => {
                console.log(err)
                reject({ 'errorType': 'createFolder', 'e': err })
              })
          }
          // should always be > 0 if the snapshot is not empty, checking anyway
          // because of the [0] below. Only one folder per student should be created. 
          else if (querySnapshot.docs.length > 0) {
            // check to see if there is only one folder, but allow continuing if there
            // are more than one.
            if (querySnapshot.docs.length !== 1) {
              console.log("changeFolder expected 1 folder, found " + querySnapshot.docs.length + " folders.")
            }

            querySnapshot.docs[0].ref.update({
              latestUpdateTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
              .then(() => {
                resolve()
              })
              .catch(err => {
                console.log(err)
                reject({ 'errorType': 'updateAlreadyCreatedFolder', 'e': err })
              })
          }
        })
    })
    )
  }

  // student folder needs to change if:
  // 1. student or goal is moving to current (needs to be set to folder current to show this)
  // 2. student (not goal) is moving from current (need to set folder to not current to show this)
  if (folderTo === FOLDER_CURRENT ||
    (folderFrom === FOLDER_CURRENT && !isNotStudent)) {
    changeFolderPromises.push(new Promise((resolve, reject) => {
      db.collection(ColType.student)
        .doc(student.id)
        .update({
          folder: folderTo
        })
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject({ 'errorType': 'changeStudentFolder', 'e': e })
        })
    })
    )
  }

  // change all iep goals folder to this folder
  IEPGoalsToTransfer.map((iep, index) => {
    changeFolderPromises.push(new Promise((resolve, reject) => {
      db.collection(ColType.iep)
        .doc(iep.id)
        .update({
          folder: folderTo
        })
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject({ 'errorType': 'changeIEPGoalFolder', 'e': e })
        })
    })
    )
    return false
  })

  return Promise.all(changeFolderPromises)
}

export function deleteIEPSFromCalendarEvents(events, iepsToRemove) {
  // remove ieps from events
  for (let calendarEvent of events) {
    for (let iep of iepsToRemove) {
      if (calendarEvent.servicedIn !== 'Teacher Event') {
        calendarEvent.ieps = calendarEvent.ieps.filter(iepObj => iepObj.iepId !== iep.id)
      }
    }
  }

  // calendarEvents now contains the ieps that are not in the iepsToRemove array
  // update the calendar events in the db
  for (let calendarEvent of events) {
    if (calendarEvent.servicedIn !== 'Teacher Event') {
      // if there are no more ieps in the event, keep an empty event
      // never update maxDuration
      db.collection(ColType.calendarEvents)
        .doc(calendarEvent.id)
        .update({
          ieps: calendarEvent.ieps,
        })
    }
  }
}

export function formatAMPM(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function getAvatarColor(grade) {
  if (!grade) return '#6813c2'
  if (grade === 'K') return '#c21382'
  else if (grade === '1') return '#2f54eb'
  else if (grade === '2') return '#722ed1'
  else if (grade === '3') return '#fa541c'
  else if (grade === '4') return '#52c41a'
  else if (grade === '5') return '#eb2f96'
  else if (grade === '6') return '#fa8c16'
  else if (grade === '7') return '#1890ff'
  else if (grade === '8') return '#a0d911'
  else if (grade === '9') return '#faad14'
  else if (grade === '10') return '#5accf1'
  else if (grade === '11') return '#102efe'
  else if (grade === '12') return '#13c2c2'
  return '#6813c2'
}

export function getInitials(person) {
  if (person.firstName === "Example" && person.lastName === "Teacher") {
    return "T"
  }
  return person.firstName.charAt(0).toUpperCase() + person.lastName.charAt(0).toUpperCase()
}

export function getQueryStringParam(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  let results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function getIDFromURL(location) {
  var url = [location.protocol, '//', location.host, location.pathname].join('')
  var split = url.split('/')
  var id = split[split.length - 1] // id is after the last '/'
  //console.log(split, split.length, id)
  return id
}

export function getURLSplitArray(location) {
  var url = [location.protocol, '//', location.host, location.pathname].join('')
  var split = url.split('/')
  return split
}

export function getIconFromMainSubject(mainSubject, tier) {
  let imageNumber = '1'
  let tierPath = '/goal-icons/'

  if (mainSubject === 'Reading Comprehension in Literature') {
    imageNumber = '2'
  } else if (mainSubject === 'Reading Comprehension in Informational Text') {
    imageNumber = '3'
  } else if (mainSubject === 'Reading Foundations') {
    imageNumber = '1'
  } else if (mainSubject === 'Math') {
    imageNumber = '5'
  } else if (mainSubject === 'Writing') {
    imageNumber = '4'
  } else if (mainSubject === 'Social Emotional Learning') {
    imageNumber = '6'
  }

  return tierPath + imageNumber + '.png'
}

export function getIconFromMainSubjectColors(mainSubject, tier) {
  let tierPath = '/iep-goal-icons/'
  let imageNumber = '1'
  if (tier === 'Tier 2') {
    tierPath = '/tier2-goal-icons/'
  } else if (tier === 'Tier 3') {
    tierPath = '/tier3-goal-icons/'
  }

  if (mainSubject === 'Reading Comprehension in Literature') {
    imageNumber = '2'
  } else if (mainSubject === 'Reading Comprehension in Informational Text') {
    imageNumber = '3'
  } else if (mainSubject === 'Reading Foundations') {
    imageNumber = '1'
  } else if (mainSubject === 'Math') {
    imageNumber = '5'
  } else if (mainSubject === 'Writing') {
    imageNumber = '4'
  } else if (mainSubject === 'Social Emotional Learning') {
    imageNumber = '6'
  }

  return tierPath + imageNumber + '.png'
}

// category is PSI, WMI, VCI, FRI, VSPI
export function isAppropriateLevel(studentLevelNumber, iep, category) {
  var num = studentLevelNumber
  var level = iep.level
  if (num <= 70) {
    if (level === category + ' 70 and below') return true
  } else if (num > 70 && num < 80) {
    if (level === category + ' 70-79') return true
  } else {
    if (level === category + ' 80 and above') return true
  }

  return false
}

export function getStandardNumber(state, grade, mainSubject, standard, standardDescription, standardCategory) {
  if (!(state, grade && mainSubject && standard && standardDescription && standardCategory)) {
    return ''
  }

  let states = ['South Carolina']

  if (states.includes(state)) {
    return ''
  }

  let numberStrings = standard.match(/^\d+|\d+\b|\d+(?=\w)/g)
  let split = standard.split(' ')
  if (numberStrings.length === 0 || split.length === 0) {
    return ''
  }

  if (mainSubject.includes('Social Emotional Learning')) {
    let standardSplit = standard.split(' ')
    let standardStr = standard
    if (standardSplit.length >= 2) {
      standardStr = standardSplit[0] + standardSplit[1]
    }
    if (standardCategory.includes('Self Awareness and Management')) {
      return '1A.' + grade + '.' + standardStr
    } else if (standardCategory.includes('Social Awareness and Interpersonal Skills') ||
      standardCategory.includes('Social-Awareness and Interpersonal Skills')) {
      return '2B.' + grade + '.' + standardStr
    } else if (standardCategory.includes('Decision Making and Responsibility')) {
      return '3C.' + grade + '.' + standardStr
    }
  }

  return split[0] + '.' + grade + '.' + numberStrings[0]
}

export function findStrategyWithLevel(strategies, level) {
  return undefined
}

export function getDefinition(paragraph) {
  // L
  if (paragraph.indexOf('cumulative, multisensory instruction') !== -1) {
    return 'Instruction that involves engaging more than one sense ' +
      'which starts with prior knowledge and builds from simple to complex'
  }
  else if (paragraph.indexOf('direct, explicit instruction') !== -1) {
    return 'Instruction that involves clear, sequenced learning goals ' +
      'presented with a brisk pace which allows for multiple student ' +
      'responses and corrective feedback'
  }
  else if (paragraph.indexOf('a teacher think aloud') !== -1) {
    return 'Teacher models verbally the problem solving ' +
      'process describing what he/she is thinking'
  }
  else if (paragraph.indexOf('concise directions') !== -1) {
    return 'Teacher uses a few words that are specific to what to do and how to do it'
  }
  else if (paragraph.indexOf('a teacher modeled example') !== -1) {
    return 'While thinking aloud, the teacher describes the ' +
      'concept and then models the desired outcome engaging the ' +
      'students by stopping to ask questions'
  }
  else if (paragraph.indexOf('multisensory vocabulary instruction') !== -1) {
    return 'Instruction using visual, auditory, tactile and ' +
      'kinesthetic (movement)  to enhance word meaning'
  }
  else if (paragraph.indexOf('teacher modeling') !== -1) {
    return 'While thinking aloud, the teacher describes the concept ' +
      'and then models the desired outcome engaging the students by ' +
      'stopping to ask questions'
  }
  else if (paragraph.indexOf('a review of prior concepts') !== -1) {
    return 'Teacher reviews the lesson objectives with examples and non-examples'
  }
  else if (paragraph.indexOf('clearly stated lesson objectives') !== -1) {
    return 'Teacher specifically states the learning goals for the lesson'
  }
  else if (paragraph.indexOf('key word vocabulary instruction') !== -1) {
    return 'Instruction using definitions with or without pictures for ' +
      'keywords and word meaning strategies such as context clues or morphology'
  }
  else if (paragraph.indexOf('objectives in student friendly language') !== -1) {
    return 'Use of a few clear and specific words to state the learning goals of the lesson'
  }
  else if (paragraph.indexOf('systematic, explicit instruction') !== -1) {
    return 'Instruction that involves clear, sequenced learning goals ' +
      'presented with a brisk pace which allows for multiple student ' +
      'responses and corrective feedback'
  }
  else if (paragraph.indexOf('instruction using real world examples') !== -1) {
    return 'Relevant instruction using examples from the students\' environment'
  }
  else if (paragraph.indexOf('systematic, cumulative instruction') !== -1) {
    return 'Instruction that involves clear, sequenced learning goals ' +
      'which progresses from simple to complex'
  }
  else if (paragraph.indexOf('multisensory instruction') !== -1) {
    return 'Instruction that involves engaging more than one sense'
  }
  else if (paragraph.indexOf('a preview of key vocabulary') !== -1) {
    return 'Instruction using definitions with or without ' +
      'pictures for keywords before the lesson'
  }
  else if (paragraph.indexOf('an opportunity to restate the lesson objectives') !== -1) {
    return 'Student restates what will be learned by completing the lesson'
  }
  else if (paragraph.indexOf('an opportunity to paraphrase clearly stated lesson objectives,')
    !== -1) {
    return 'Student restates the lesson objective in own words'
  }
  else if (paragraph.indexOf('a preview of 1-3 key vocabulary words') !== -1) {
    return 'Instruction using definitions with or without ' +
      'pictures for keywords before the lesson'
  }
  else if (paragraph.indexOf('systematic, explicit writing instruction,') !== -1) {
    return 'Writing instruction that involves clear, sequenced ' +
      'learning goals which allows for multiple student responses'
  }
  else if (paragraph.indexOf('instruction in writing purpose and organization') !== -1) {
    return 'Writing instruction with a focus on the reason for ' +
      'writing and the internal structure'
  }
  else if (paragraph.indexOf('an opportunity to brainstorm') !== -1) {
    return 'Peers sharing ideas about writing topics and key details'
  }
  else if (paragraph.indexOf('explicit writing instruction,') !== -1) {
    return 'Writing instruction which includes teacher modeling, ' +
      'guided practice, and independent practice'
  }
  else if (paragraph.indexOf('an opportunity to record ideas prior to writing,') !== -1) {
    return 'Student is able to write down or use a recording ' +
      'device of ideas before starting the writing task'
  }
  else if (paragraph.indexOf('an opportunity to paraphrase the writing purpose,') !== -1) {
    return 'Student restates the reason for writing such as: ' +
      'inform, entertain, explain, or persuade in own words'
  }
  else if (paragraph.indexOf('direct, explicit writing instruction') !== -1) {
    return 'Instruction that involves clear, sequenced learning goals ' +
      'and allows for multiple student responses and corrective feedback'
  }
  else if (paragraph.indexOf('writing instruction with a focus on quality over quantity,') !== -1) {
    return 'Emphasis on a high standard of written product ' +
      'versus multiple products of lower standards'
  }
  else if (paragraph.indexOf('a choice of writing topic based on personal interests,') !== -1) {
    return 'Topic based on what the student wants to write about'
  }
  else if (paragraph.indexOf('explicit, key word vocabulary instruction') !== -1) {
    return 'Instruction using definitions with or without pictures ' +
      'for keywords and word meaning strategies such as context ' +
      'clues or morphology'
  }
  else if (paragraph.indexOf('guided writing instruction,') !== -1) {
    return 'Writing instruction with a small group of students ' +
      'where the teacher supports the writing task giving step by ' +
      'step directions if needed'
  }
  else if (paragraph.indexOf('an activating strategy to connect to prior knowledge,') !== -1) {
    return 'A strategy that provides a hook to motivate and a link ' +
      'to prior knowledge such as a KWL chart'
  }
  else if (paragraph.indexOf('explicit, individualized instruction') !== -1) {
    return 'Specifically designed instruction which includes clear, sequenced learning goals'
  }
  else if (paragraph.indexOf('individualized instruction in a predictable routine') !== -1) {
    return 'Specifically designed instruction  in a consistent learning ' +
      'environment with clear expectations of what to do and what is coming next'
  }
  else if (paragraph.indexOf('concise, individualized behavior instruction') !== -1) {
    return 'Specifically designed instruction which includes ' +
      'clearly defined behavioral expectations'
  }
  else if (paragraph.indexOf('explicit, individualized behavior instruction') !== -1) {
    return 'Specifically designed instruction which includes clear, ' +
      'sequenced behavioral goals'
  }
  else if (paragraph.indexOf('appropriately paced direct, explicit instruction') !== -1) {
    return 'Instruction with a pace to keep students\' attention and varied ' +
      'teacher inflections that involves clear, sequenced learning goals and ' +
      'allows for multiple student responses and corrective feedback'
  }
  else if (paragraph.indexOf('direct instruction in key vocabulary words') !== -1) {
    return 'Instruction that includes definitions and reading the word in different contexts ' +
      'with frequent teacher feedback'
  }
  else if (paragraph.indexOf('instruction in problem solving strategies,') !== -1) {
    return 'Instruction in developing a plan to find a solution'
  }
  else if (paragraph.indexOf('direct instruction') !== -1) {
    return 'Instruction that uses structured and sequenced materials with ' +
      'clear learning goals and frequent teacher feedback'
  }

  // M
  else if (paragraph.indexOf('tasklist') !== -1) {
    return 'Written steps (can include pictures) that break an assignment into smaller parts'
  }
  else if (paragraph.indexOf('exemplar') !== -1) {
    return 'A visual example of what the student needs to do to complete the learning task'
  }
  else if (paragraph.indexOf('picture supports') !== -1) {
    return 'Pictures, drawings, or photos used to support comprehension of information'
  }
  else if (paragraph.indexOf('predictable routine') !== -1) {
    return 'A consistent learning environment with clear ' +
      'expectations of what to do and what is coming next'
  }
  else if (paragraph.indexOf('an opportunity to paraphrase directions') !== -1) {
    return 'Student restates directions using own words'
  }
  else if (paragraph.indexOf('chunking of the task') !== -1) {
    return 'Breaking the task into smaller parts'
  }
  else if (paragraph.indexOf('quickly paced with routine prompting') !== -1) {
    return 'Fast moving instruction using teacher verbal, visual, or gestural cues'
  }
  else if (paragraph.indexOf('a focus on nonverbal cues') !== -1) {
    return 'Cues that include body language, tone of voice, inflection'
  }
  else if (paragraph.indexOf('graphic organizer') !== -1) {
    return 'A visual tool to organize information'
  }
  else if (paragraph.indexOf('vocabulary handout') !== -1) {
    return 'Definitions with or without  pictures for keywords'
  }
  else if (paragraph.indexOf('with appropriate pace and tone') !== -1) {
    return 'Instruction with a pace to keep students\' ' +
      'attention and varied teacher inflections'
  }
  else if (paragraph.indexOf('with wait time for responses') !== -1) {
    return 'Giving students 5 seconds to respond to a question'
  }
  else if (paragraph.indexOf('linked to real life experiences') !== -1) {
    return 'Relevant instruction using examples from the students\' environment'
  }
  else if (paragraph.indexOf('the opportunity to reason aloud') !== -1) {
    return 'The student verbally describing what he/she is ' +
      'thinking during the problem solving process'
  }
  else if (paragraph.indexOf('with algebra tiles and picture models') !== -1) {
    return 'Use of tiles to represent variables and constants ' +
      'with pictures or drawings to support comprehension'
  }
  else if (paragraph.indexOf('using manipulatives and picture models') !== -1) {
    return 'use of objects with pictures or drawings to support comprehension'
  }
  else if (paragraph.indexOf('a graphic organizer related to the lesson objective') !== -1) {
    return 'A visual tool to organize information related to lesson objective'
  }
  else if (paragraph.indexOf('an anchor chart') !== -1) {
    return 'Poster with information to support previous ' +
      'lessons and/or reminder of key concepts'
  }
  else if (paragraph.indexOf('appropriately paced in a predictable routine') !== -1) {
    return 'Teacher instruction is given at the right speed in a ' +
      'consistent learning environment with clear expectations of ' +
      'what to do and what is coming next'
  }
  else if (paragraph.indexOf('using real world examples') !== -1) {
    return 'Models of what is occurring in everyday life'
  }
  else if (paragraph.indexOf('an exemplar') !== -1) {
    return 'A visual example of what the student needs ' +
      'to do to complete the learning task'
  }
  else if (paragraph.indexOf('using pictures and/or objects') !== -1) {
    return 'Pictures, drawings, objects, or photos used ' +
      'to support comprehension of information'
  }
  else if (paragraph.indexOf('with a graphic organizer') !== -1) {
    return 'A visual tool to organize information'
  }
  else if (paragraph.indexOf('a vocabulary handout') !== -1) {
    return 'Definitions with or without  pictures for keywords'
  }
  else if (paragraph.indexOf('using an exemplar') !== -1) {
    return 'A visual example of what the student needs to ' +
      'do to complete the learning task'
  }
  else if (paragraph.indexOf('a labeled graphic organizer') !== -1) {
    return 'A visual tool to organize information with keywords added'
  }
  else if (paragraph.indexOf('a concept map') !== -1) {
    return 'Diagram made up of circles and boxes ' +
      'that shows the connection and relationship of ideas'
  }
  else if (paragraph.indexOf('access to assistive writing technology') !== -1) {
    return 'Some examples of software include: speech to text, ' +
      'word prediction, spell check and grammar check'
  }
  else if (paragraph.indexOf('a graphic organizer') !== -1) {
    return 'A visual tool to organize information'
  }
  else if (paragraph.indexOf('a writing mnemonic to facilitate organization') !== -1) {
    return 'Memory aid to support remembering how to ' +
      'organize the writing process or improve the quality'
  }
  else if (paragraph.indexOf('in chunked assignments') !== -1) {
    return 'Dividing the assignment into manageable parts'
  }
  else if (paragraph.indexOf('collaborative pairing') !== -1) {
    return 'Peers working together to achieve the lesson objective'
  }
  else if (paragraph.indexOf('a concept map') !== -1) {
    return 'Diagram made up of circles and boxes that ' +
      'shows the connection and relationship of ideas'
  }
  else if (paragraph.indexOf('with captioned images related to the topic') !== -1) {
    return 'Pictures with corresponding words relevant to the topic'
  }
  else if (paragraph.indexOf('a word bank') !== -1) {
    return 'List of key vocabulary words'
  }
  else if (paragraph.indexOf('collaborative pairing to verbalize ideas') !== -1) {
    return 'Peers working together to brainstorm'
  }
  else if (paragraph.indexOf('in decision making skills') !== -1) {
    return 'Determining the best course of action by ' +
      'weighing the pros and cons of the options'
  }
  else if (paragraph.indexOf('with frequent practice') !== -1) {
    return 'Repeating an activity'
  }
  else if (paragraph.indexOf('in reading social cues and empathizing with others') !== -1) {
    return 'Understanding non-verbal communication such as: body language, ' +
      'tone of voice, and personal space as well as how to ' +
      'understand others\' feelings'
  }
  else if (paragraph.indexOf('in how to work in a group') !== -1) {
    return 'Understanding group norms to achieve a common goal'
  }
  else if (paragraph.indexOf('picture models to teach the new concept,') !== -1) {
    return 'Use of images to represent numbers and operations when teaching a new concept'
  }
  else if (paragraph.indexOf('picture models,') !== -1) {
    return 'Use of images to represent numbers and operations'
  }
  else if (paragraph.indexOf('repeated practice') !== -1) {
    return 'Completing an activity over and over'
  }
  else if (paragraph.indexOf('with manipulatives and picture models') !== -1) {
    return 'Physical objects used to teach a math concept such as ' +
      'color tiles or base ten blocks as well as images used to ' +
      'represent numbers and operations'
  }
  else if (paragraph.indexOf('frequent practice') !== -1) {
    return 'Completing an activity over and over'
  }

  // N
  else if (paragraph.indexOf('mastery oriented feedback') !== -1) {
    return 'Feedback that emphasises effort, persistence, and ' +
      'practice to support the student towards mastery of a concept'
  }
  else if (paragraph.indexOf('checks for understanding with corrective feedback') !== -1) {
    return 'Frequent assessing to determine whether the student is ' +
      'understanding what is being taught (ex. questioning, exit slips) ' +
      'also letting the student know what he/she has done well and what ' +
      'needs improvement'
  }
  else if (paragraph.indexOf('descriptive feedback') !== -1) {
    return 'Feedback that is given either verbally or in writing ' +
      'about specific details of what the student accomplished and ' +
      'what the next steps would be for reaching the learning goal'
  }
  else if (paragraph.indexOf('reflective feedback') !== -1) {
    return 'Process of self assessment and a determination ' +
      'of a pathway for improvement'
  }
  else if (paragraph.indexOf('immediate feedback') !== -1) {
    return 'Teacher tells student instantly what is ' +
      'going well and what needs to be corrected'
  }
  else if (paragraph.indexOf('corrective feedback') !== -1) {
    return 'Teacher tells student what is going well ' +
      'and what needs improvement'
  }

  // other
  else if (paragraph.indexOf('with a task checklist') !== -1) {
    return 'Written steps (can include pictures) that ' +
      'break an assignment into smaller parts'
  }
  else if (paragraph.indexOf('and checks for understanding') !== -1) {
    return 'Frequent assessing to determine whether the student ' +
      'is understanding what is being taught'
  }
  else if (paragraph.indexOf('quickly paced') !== -1) {
    return 'Fast moving instruction using teacher ' +
      'verbal, visual, or gestural cues'
  }
  else if (paragraph.indexOf('with picture models') !== -1) {
    return 'A picture that supports the concepts being taught'
  }
  else if (paragraph.indexOf('using picture models') !== -1) {
    return 'A picture that supports the concepts being taught'
  }
  else if (paragraph.indexOf('an outline') !== -1) {
    return 'An abbreviated version of the key concepts of a lesson'
  }
  else if (paragraph.indexOf('a teacher modeled think aloud') !== -1) {
    return 'While thinking aloud, the teacher describes ' +
      'the concept and then models the desired outcome ' +
      'engaging the students by stopping to ask questions'
  }
  else if (paragraph.indexOf('keyword vocabulary instruction') !== -1) {
    return 'Instruction using definitions with or without ' +
      'pictures for keywords and word meaning strategies ' +
      'such as context clues or morphology'
  }
  else if (paragraph.indexOf('teacher modeled examples') !== -1) {
    return 'While thinking aloud, the teacher describes ' +
      'the concept and then models the desired outcome. '
  }
  else if (paragraph.indexOf('annotation strategies') !== -1) {
    return 'Adding a note next to the text and/or underlining ' +
      'key information'
  }
  else if (paragraph.indexOf('a highlighter and/or sticky notes') !== -1) {
    return 'A fluorescent marker or sticky note used to ' +
      'mark important information. '
  }

  console.log("definition not found", paragraph)
  return ''
}

export function gradeText(grade) {
  var gradeLevel = grade
  if (typeof grade === 'string' && grade !== 'K' && grade !== 'preK') {
    gradeLevel = parseInt(grade, 10)
  }

  if (gradeLevel === 'preK') {
    return 'preK'
  } else if (gradeLevel === 'K') {
    return 'indergarten'
  } else if (gradeLevel === 1) {
    return 'st Grade'
  } else if (gradeLevel === 2) {
    return 'nd Grade'
  } else if (gradeLevel === 3) {
    return 'rd Grade'
  } else {
    return 'th Grade'
  }
}

export function flattenDoc(doc) {
  var map = doc.data()
  map.id = doc.id
  return map
}


export function createIEPGoalText(iep, student, numProgressMonitoringScores) {
  let willStatement = iep.standardCategory.toLowerCase()
  if (iep.mainSubject === "Reading Comprehension in Literature" ||
    iep.mainSubject === "Reading Comprehension in Informational Text") {
    willStatement = 'reading comprehension'
  }

  var iepParagraph = 'By ' +
    (iep.completionDate === '<date>,' ? '<date>' : iep.completionDate) + ', given '

  iepParagraph += iep["given"][0] + ", "

  iepParagraph += student.firstName + ' will improve skills in ' + willStatement + ' by '

  if (numProgressMonitoringScores === 2) {
    iepParagraph += iep["studentWill"][0] + " " + removeLastCommaIfNeeded(iep["studentWill"][2]) + " and " +
      iep["studentWill"][5] + " " + removeLastCommaIfNeeded(iep["studentWill"][6]) + " "
  } else if (numProgressMonitoringScores === 3) {
    iepParagraph += iep["studentWill"][0] + " " + iep["studentWill"][2] + " " + iep["studentWill"][5] +
      " " + iep["studentWill"][6] + " and " + iep["studentWill"][7] + " " + removeLastCommaIfNeeded(iep["studentWill"][8]) + " "
  } else {
    iepParagraph += iep["studentWill"][0] + " " + iep["studentWill"][2] + " " + iep["studentWill"][5] +
      " " + iep["studentWill"][6] + " " + iep["studentWill"][7] + " " + iep["studentWill"][8] +
      " " + iep["studentWill"][9] + " " + iep["studentWill"][10] + " "
  }

  iepParagraph += 'from ' + iep.baselineAccuracyLevel + '% '
  iepParagraph += 'to ' + iep.targetAccuracyLevel + '% accuracy measured bimonthly'
  iepParagraph += ' by ' + iep.by

  return iepParagraph
}

export function removeLastCommaIfNeeded(strVal) {
  if (strVal.length === 0) {
    return strVal
  }
  else if (strVal[strVal.length - 1] === ',') {
    return strVal.substring(0, strVal.length - 1)
  }

  return strVal
}

export function addLastCommaIfNeeded(strVal) {
  if (strVal.length === 0) {
    return strVal
  }
  else if (strVal[strVal.length - 1] !== ',') {
    return strVal + ','
  }

  return strVal
}

export function createMTSSGoalText(iep, student, numProgressMonitoringScores) {
  var iepParagraph = 'By ' +
    (iep.completionDate === '<date>,' ? '<date>' : iep.completionDate) + ', given '

  iepParagraph += iep["given"][0] + " "

  iepParagraph += student.firstName + ' will '

  iepParagraph += iep['given'][1] + ' by ' + iep['given'][2] + ' '

  if (numProgressMonitoringScores === 2) {
    iepParagraph += removeLastCommaIfNeeded(iep["studentWill"][1]) + ' and ' +
      iep["studentWill"][4] + ' ' + removeLastCommaIfNeeded(iep["studentWill"][5]) + ' '
  } else if (numProgressMonitoringScores === 3) {
    iepParagraph += addLastCommaIfNeeded(iep["studentWill"][1]) + ' ' +
      iep["studentWill"][4] + ' ' + iep["studentWill"][5] + ' and ' +
      removeLastCommaIfNeeded(iep["studentWill"][6]) + ' ' + removeLastCommaIfNeeded(iep["studentWill"][7]) + ' '
  } else {
    iepParagraph += addLastCommaIfNeeded(iep["studentWill"][1]) + ' ' + iep["studentWill"][4] + ' ' + iep["studentWill"][5] +
      " " + removeLastCommaIfNeeded(iep["studentWill"][6]) + " " + iep["studentWill"][7] + " " + iep["studentWill"][8] +
      " " + iep["studentWill"][9] + " "
  }

  var measuredVal = 'bimonthly'
  if (iep.hasOwnProperty('tier') && iep.tier === 'Tier 3') {
    measuredVal = 'weekly'
  }
  iepParagraph += 'from ' + iep.baselineAccuracyLevel + '% '
  iepParagraph += 'to ' + iep.targetAccuracyLevel + '% accuracy measured ' + measuredVal
  iepParagraph += ' by ' + iep.measured

  return iepParagraph
}

export function removePeriodFromSentenceIfNeeded(sentence) {
  if (sentence.length === 0) {
    return sentence
  } else if (sentence[sentence.length - 1] === ".") {
    return sentence.slice(0, -1)
  }

  return sentence
}

export function getCategoryImpact(iep) {
  if (iep.hasOwnProperty('category')) {
    if (iep.category === "WMI") {
      return "memory skills"
    } else if (iep.category === "PSI") {
      return 'processing ability'
    } else if (iep.category === "VSI") {
      return 'visual spatial skills'
    } else if (iep.category === "VCI") {
      return 'verbal skills'
    } else if (iep.category === "FRI") {
      return 'problem solving skills'
    }
    // use the old sentence if for some reason iep has a different category
    if (iep.hasOwnProperty('subject')) {
      return iep.subject
    }
    return ''
  }
  // use the old sentence if for some reason iep doesn't have a category 
  else if (iep.hasOwnProperty('subject')) {
    return iep.subject
  }
  return ''
}

export function createSELPresentLevelText(iep, presentLevelObj, student, strength) {
  var presentLevel = ''

  if (iep.subject === 'social emotional learning') {
    presentLevel += 'According to teacher observations over the past 6 weeks'
    presentLevel += ', ' + student.firstName
    presentLevel += ' needs instruction in ' + iep.subject + '. '
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
    presentLevel += student.firstName + ' needs to improve'
    presentLevel += ' skills related to ' + removePeriodFromSentenceIfNeeded(iep.standardDescription)
    presentLevel += ' to access the general curriculum.'
  }

  return presentLevel
}

export function createELAPresentLevelText(iep, presentLevelObj, student, strength) {
  var presentLevel = ''

  if (iep.subject === 'social emotional learning') {
    presentLevel += 'According to teacher observations over the past 6 weeks'
    presentLevel += ', ' + student.firstName
    presentLevel += ' needs instruction in ' + iep.subject + '. '
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
    presentLevel += student.firstName + ' needs to improve'
    presentLevel += ' skills related to ' + removePeriodFromSentenceIfNeeded(iep.standardDescription)
    presentLevel += ' to access the general curriculum.'
  }
  else if (iep.subject === 'writing') {
    presentLevel += 'According to student writing  samples over the past 6 weeks, '
    presentLevel += student.firstName + ' needs to improve skills related to '
    presentLevel += removePeriodFromSentenceIfNeeded(iep.standardDescription)
    presentLevel += ' to access the general curriculum.'
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '.'
  }
  else {
    if (student.grade >= 4 && student.grade <= 8) {
      presentLevel += 'Based on the state assessment in '
      presentLevel += 'ELA, '
      presentLevel += student.firstName + ' scored level '
      presentLevel += presentLevelObj.elaStateScore
      presentLevel += ' indicating that ' + student.firstName
      presentLevel += ' has ' + presentLevelObj.elaStateMet.toLowerCase()
      presentLevel += ' standards expectations in reading. '
    }
    presentLevel += 'On a norm referenced assessment ('
    presentLevel += presentLevelObj.elaNormAssessment + '),'
    presentLevel += ' administered on ' +
      (presentLevelObj.elaNormAssessment !== "Not known at this time" ?
        presentLevelObj.elaNormDate : "(Not known at this time)")
    presentLevel += ', ' + student.firstName
    presentLevel += ' scored in the ' + presentLevelObj.elaNormLevel.toLowerCase()
    presentLevel += ' range compared to grade level peers.'
    presentLevel += ' Overall, ' + student.firstName + ' is'
    presentLevel += ' performing at a ' + gradeToGradeLevel(presentLevelObj.elaGradeLevel)
    presentLevel += ' grade level in reading. This assessment shows that '
    presentLevel += student.firstName + ' is performing lowest in the area of '
    presentLevel += iep.subject + '.'
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
    presentLevel += student.firstName + ' needs to'
    presentLevel += ' improve skills related to ' + removePeriodFromSentenceIfNeeded(iep.standardDescription)
    presentLevel += ' to access the general curriculum.'
  }

  return presentLevel
}

export function createMathPresentLevelText(iep, presentLevelObj, student, strength) {
  var presentLevel = ''

  if (student.grade >= 4 && student.grade <= 8) {
    presentLevel += 'Based on the state assessment in '
    presentLevel += 'Math, '
    presentLevel += student.firstName + ' scored level '
    presentLevel += presentLevelObj.mathStateScore
    presentLevel += ' indicating that ' + student.firstName
    presentLevel += ' has ' + presentLevelObj.mathStateMet.toLowerCase()
    presentLevel += ' standards expectations in math. '
  }
  presentLevel += 'On a norm referenced assessment ('
  presentLevel += presentLevelObj.mathNormAssessment + '),'
  presentLevel += ' administered on ' +
    (presentLevelObj.mathNormAssessment !== "Not known at this time" ?
      presentLevelObj.mathNormDate : "(Not known at this time)")
  presentLevel += ', ' + student.firstName
  presentLevel += ' scored in the ' + presentLevelObj.mathNormLevel.toLowerCase()
  presentLevel += ' range compared to grade level peers.'
  presentLevel += ' Overall, ' + student.firstName + ' is'
  presentLevel += ' performing at a ' + gradeToGradeLevel(presentLevelObj.mathGradeLevel)
  presentLevel += ' grade level in math. This assessment shows that '
  presentLevel += student.firstName + ' is performing lowest in the area of '
  presentLevel += iep.subject + '.'
  presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
  presentLevel += student.firstName + ' needs to'
  presentLevel += ' improve skills related to ' + removePeriodFromSentenceIfNeeded(iep.standardDescription)
  presentLevel += ' to access the general curriculum.'

  return presentLevel
}


export function createSELPresentLevelTextMTSS(iep, presentLevelObj, student, strength) {
  var presentLevel = ''

  if (iep.subject === 'social emotional learning') {
    presentLevel += 'According to teacher observations over the past 6 weeks'
    presentLevel += ', ' + student.firstName
    presentLevel += ' needs instruction in ' + iep.subject + '. '
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
    presentLevel += student.firstName + ' needs to improve'
    presentLevel += ' skills related to ' + iep.standardDescription
  }

  presentLevel += " " + student.firstName + " requires " + iep.impactDegree + " " + iep.mainSubject.toLowerCase() + '.'

  return presentLevel
}

export function createELAPresentLevelTextMTSS(iep, presentLevelObj, student, strength) {
  var presentLevel = ''

  if (iep.subject === 'social emotional learning') {
    presentLevel += 'According to teacher observations over the past 6 weeks'
    presentLevel += ', ' + student.firstName
    presentLevel += ' needs instruction in ' + iep.subject + '. '
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
    presentLevel += student.firstName + ' needs to improve'
    presentLevel += ' skills related to ' + iep.standardDescription
  }
  else if (iep.subject === 'writing') {
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
    presentLevel += 'According to student writing  samples over the past 6 weeks, '
    presentLevel += student.firstName + ' needs to improve skills related to '
    presentLevel += iep.standardDescription
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '.'
  }
  else {
    if (student.grade >= 4 && student.grade <= 8) {
      presentLevel += 'Based on the state assessment in '
      presentLevel += 'ELA, '
      presentLevel += student.firstName + ' scored level '
      presentLevel += presentLevelObj.elaStateScore
      presentLevel += ' indicating that ' + student.firstName
      presentLevel += ' has ' + presentLevelObj.elaStateMet.toLowerCase()
      presentLevel += ' standards expectations in reading. '
    }
    presentLevel += 'On a norm referenced assessment ('
    presentLevel += presentLevelObj.elaNormAssessment + '),'
    presentLevel += ' administered on ' +
      (presentLevelObj.elaNormAssessment !== "Not known at this time" ?
        presentLevelObj.elaNormDate : "(Not known at this time)")
    presentLevel += ', ' + student.firstName
    presentLevel += ' scored in the ' + presentLevelObj.elaNormLevel.toLowerCase()
    presentLevel += ' range compared to grade level peers.'
    presentLevel += ' Overall, ' + student.firstName + ' is'
    presentLevel += ' performing at a ' + gradeToGradeLevel(presentLevelObj.elaGradeLevel)
    presentLevel += ' grade level in reading. This assessment shows that '
    presentLevel += student.firstName + ' is performing lowest in the area of '
    presentLevel += iep.subject + '. '
    presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '
  }

  presentLevel += " " + student.firstName + " requires " + iep.impactDegree + " " + iep.mainSubject.toLowerCase() + '.'

  return presentLevel
}

export function createMathPresentLevelTextMTSS(iep, presentLevelObj, student, strength) {
  var presentLevel = ''

  if (student.grade >= 4 && student.grade <= 8) {
    presentLevel += 'Based on the state assessment in '
    presentLevel += 'Math, '
    presentLevel += student.firstName + ' scored level '
    presentLevel += presentLevelObj.mathStateScore
    presentLevel += ' indicating that ' + student.firstName
    presentLevel += ' has ' + presentLevelObj.mathStateMet.toLowerCase()
    presentLevel += ' standards expectations in math. '
  }
  presentLevel += 'On a norm referenced assessment ('
  presentLevel += presentLevelObj.mathNormAssessment + '),'
  presentLevel += ' administered on ' +
    (presentLevelObj.mathNormAssessment !== "Not known at this time" ?
      presentLevelObj.mathNormDate : "(Not known at this time)")
  presentLevel += ', ' + student.firstName
  presentLevel += ' scored in the ' + presentLevelObj.mathNormLevel.toLowerCase()
  presentLevel += ' range compared to grade level peers.'
  presentLevel += ' Overall, ' + student.firstName + ' is'
  presentLevel += ' performing at a ' + gradeToGradeLevel(presentLevelObj.mathGradeLevel)
  presentLevel += ' grade level in math. This assessment shows that '
  presentLevel += student.firstName + ' is performing lowest in the area of '
  presentLevel += iep.subject + '. '
  presentLevel += ' ' + student.firstName + ' has a strength in ' + strength + '. '

  presentLevel += " " + student.firstName + " requires " + iep.impactDegree + " " + iep.mainSubject.toLowerCase() + '.'

  return presentLevel
}

export function getSpecialEducationRemovalStatement(student, category, level) {
  var removalStatement = student.firstName + ' requires '

  if (category === 'PSI') {
    removalStatement += 'appropriately paced instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'WMI') {
    removalStatement += 'multisensory instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'VCI') {
    removalStatement += 'visually supported instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'FRI') {
    removalStatement += 'step-by-step instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'VSI') {
    removalStatement += 'clear verbal instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  } else {
    // default case
    removalStatement += 'clear verbal instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }

  return removalStatement
}


export function getMathSpecialEducationRemovalStatement(student, category, level) {
  var removalStatement = student.firstName + ' requires '

  if (category === 'PSI') {
    removalStatement += 'appropriately paced instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'WMI') {
    removalStatement += 'multisensory instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'VCI') {
    removalStatement += 'visually supported instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'FRI') {
    removalStatement += 'step-by-step instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }
  else if (category === 'VSI') {
    removalStatement += 'clear verbal instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  } else {
    // default case
    removalStatement += 'clear verbal instruction of grade-level concepts using instructional level materials in a small group to build the knowledge and skills needed to access the general curriculum.'
  }

  return removalStatement
}

export function genderToPronoun(gender) {
  if (gender === 'Male') return 'he'
  else return 'she'
}

export function gradeToGradeLevel(grade) {
  if (grade === '(Not known at this time)')
    return '(Not known at this time)'
  if (grade === 'preK') return 'preK'
  if (grade === 'K') return 'kindergarten'
  if (grade === '1') return '1st'
  if (grade === '2') return '2nd'
  if (grade === '3') return '3rd'
  else return grade + 'th'
}

export function getGradeLevelText(grade) {
  if (grade === 'K') {
    return 'Kindergarten'
  } else if (grade === 'preK') {
    return 'preK'
  }

  var gradeLevel = parseInt(grade, 10)
  var suffix = 'th'

  if (gradeLevel === 1) {
    suffix = 'st'
  } else if (gradeLevel === 2) {
    suffix = 'nd'
  } else if (gradeLevel === 3) {
    suffix = 'rd'
  }

  return gradeLevel + suffix + ' Grade'
}

export function getStandardsColType(state) {
  if (state === 'KY') {
    return ColType.kentuckyStandards
  }
  return ColType.scStandards
}

export function getStateStandardNamesDict(state) {
  if (state === 'KY') {
    return {
      'ELA': [
        'Kindergarten',
        '1st Grade',
        '2nd Grade',
        '3rd Grade',
        '4th Grade',
        '5th Grade',
        '6th Grade',
        '7th Grade',
        '8th Grade',
        '9th Grade',
        '10th Grade',
        '11th Grade',
        '12th Grade'
      ],
      'ELADisplayNames': {
        'Kindergarten': 'Kindergarten ELA',
        '1st Grade': '1st Grade ELA',
        '2nd Grade': '2nd Grade ELA',
        '3rd Grade': '3rd Grade ELA',
        '4th Grade': '4th Grade ELA',
        '5th Grade': '5th Grade ELA',
        '6th Grade': '6th Grade ELA',
        '7th Grade': '7th Grade ELA',
        '8th Grade': '8th Grade ELA',
        '9th Grade': '9th Grade ELA',
        '10th Grade': '10th Grade ELA',
        '11th Grade': '11th Grade ELA',
        '12th Grade': '12th Grade ELA'
      },
      'Math': [
        'Kindergarten',
        '1st Grade',
        '2nd Grade',
        '3rd Grade',
        '4th Grade',
        '5th Grade',
        '6th Grade',
        '7th Grade',
        '8th Grade',
        'Number and Quantity',
        'Algebra',
        'Functions',
        'Geometry',
        'Statistics and Probability',
        'Calculus'
      ],
      'MathDisplayNames': {
        'Kindergarten': 'Kindergarten Math',
        '1st Grade': '1st Grade Math',
        '2nd Grade': '2nd Grade Math',
        '3rd Grade': '3rd Grade Math',
        '4th Grade': '4th Grade Math',
        '5th Grade': '5th Grade Math',
        '6th Grade': '6th Grade Math',
        '7th Grade': '7th Grade Math',
        '8th Grade': '8th Grade Math',
        'Number and Quantity': 'Number and Quantity',
        'Algebra': 'Algebra',
        'Functions': 'Functions',
        'Geometry': 'Geometry',
        'Statistics and Probability': 'Statistics and Probability',
        'Calculus': 'Calculus'
      },
      'Social Studies': [
        'Kindergarten',
        '1st Grade',
        '2nd Grade',
        '3rd Grade',
        '4th Grade',
        '5th Grade',
        '6th Grade',
        '7th Grade',
        '8th Grade',
        'Civics',
        'Economics',
        'Geography',
        'United States History',
        'World History'
      ],
      'Social StudiesDisplayNames': {
        'Kindergarten': 'Kindergarten Social Studies',
        '1st Grade': '1st Grade Social Studies',
        '2nd Grade': '2nd Grade Social Studies',
        '3rd Grade': '3rd Grade Social Studies',
        '4th Grade': '4th Grade Social Studies',
        '5th Grade': '5th Grade Social Studies',
        '6th Grade': '6th Grade Social Studies',
        '7th Grade': '7th Grade Social Studies',
        '8th Grade': '8th Grade Social Studies',
        'Civics': 'Civics',
        'Economics': 'Economics',
        'Geography': 'Geography',
        'United States History': 'United States History',
        'World History': 'World History'
      },
      'Science': [
        'Kindergarten',
        '1st Grade',
        '2nd Grade',
        '3rd Grade',
        '4th Grade',
        '5th Grade',
        '6th Grade',
        '7th Grade',
        '8th Grade',
        'High School Earth and Space Sciences',
        'High School Life Sciences',
        'High School Physical Sciences',
      ],
      'ScienceDisplayNames': {
        'Kindergarten': 'Kindergarten Science',
        '1st Grade': '1st Grade Science',
        '2nd Grade': '2nd Grade Science',
        '3rd Grade': '3rd Grade Science',
        '4th Grade': '4th Grade Science',
        '5th Grade': '5th Grade Science',
        '6th Grade': '6th Grade Science',
        '7th Grade': '7th Grade Science',
        '8th Grade': '8th Grade Science',
        'High School Earth and Space Sciences': 'High School Earth and Space Sciences',
        'High School Life Sciences': 'High School Life Sciences',
        'High School Physical Sciences': 'High School Physical Sciences',
      }
    }
  }

  // for now always return SC, later we can add other states
  return {
    'ELA': [
      'Kindergarten',
      '1st Grade',
      '2nd Grade',
      '3rd Grade',
      '4th Grade',
      '5th Grade',
      '6th Grade',
      '7th Grade',
      '8th Grade',
      'English 1',
      'English 2',
      'English 3',
      'English 4'
    ],
    'ELADisplayNames': {
      'Kindergarten': 'Kindergarten ELA',
      '1st Grade': '1st Grade ELA',
      '2nd Grade': '2nd Grade ELA',
      '3rd Grade': '3rd Grade ELA',
      '4th Grade': '4th Grade ELA',
      '5th Grade': '5th Grade ELA',
      '6th Grade': '6th Grade ELA',
      '7th Grade': '7th Grade ELA',
      '8th Grade': '8th Grade ELA',
      'English 1': 'English 1',
      'English 2': 'English 2',
      'English 3': 'English 3',
      'English 4': 'English 4'
    },
    'Math': [
      'Kindergarten',
      '1st Grade',
      '2nd Grade',
      '3rd Grade',
      '4th Grade',
      '5th Grade',
      '6th Grade',
      '7th Grade',
      '8th Grade',
      'Algebra 1',
      'Foundations in Algebra',
      'Intermediate Algebra',
      'Algebra 2',
      'Geometry',
      'Probability and Statistics',
      'Pre-Calculus',
      'Calculus',
    ],
    'FutureMath': [
      'Kindergarten',
      '1st Grade',
      '2nd Grade',
      '3rd Grade',
      '4th Grade',
      '5th Grade',
      '6th Grade',
      '7th Grade',
      '7th and 8th Grade Compacted',
      '8th Grade',
      '8th Grade and Geometry Compacted',
      'Geometry with Statistics',
      'Algebra 1',
      'Algebra 2 with Probability',
      'Pre-Calculus',
      'Calculus',
      'Reasoning in Mathematics',
      'Applications and Modeling',
      'Statistical Modeling',
      'Discrete Mathematics',
    ],
    'MathDisplayNames': {
      'Kindergarten': 'Kindergarten Math',
      '1st Grade': '1st Grade Math',
      '2nd Grade': '2nd Grade Math',
      '3rd Grade': '3rd Grade Math',
      '4th Grade': '4th Grade Math',
      '5th Grade': '5th Grade Math',
      '6th Grade': '6th Grade Math',
      '7th Grade': '7th Grade Math',
      '7th and 8th Grade Compacted': '7th and 8th Grade Compacted',
      '8th Grade': '8th Grade Math',
      '8th Grade and Geometry Compacted': '8th Grade and Geometry Compacted',
      'Algebra 1': 'Algebra 1',
      'Algebra 2': 'Algebra 2',
      'Algebra 2 with Probability': 'Algebra 2 with Probability',
      'Applications and Modeling': 'Applications and Modeling',
      'Geometry with Statistics': 'Geometry with Statistics',
      'Reasoning in Mathematics': 'Reasoning in Mathematics',
      'Statistical Modeling': 'Statistical Modeling',
      'Calculus': 'Calculus',
      'Foundations in Algebra': 'Foundations in Algebra',
      'Geometry': 'Geometry',
      'Intermediate Algebra': 'Intermediate Algebra',
      'Pre-Calculus': 'Pre-Calculus',
      'Probability and Statistics': 'Probability and Statistics',
      'Discrete Mathematics': 'Discrete Mathematics'
    },
    'Social Studies': [
      'Kindergarten',
      '1st Grade',
      '2nd Grade',
      '3rd Grade',
      '4th Grade',
      '5th Grade',
      '6th Grade',
      '7th Grade',
      '8th Grade',
      'Human Geography',
      'Modern World History',
      'United States History and the Constitution',
      'United States Government',
      'Economics and Personal Finance',
      'History and Literature of the Old Testament Era',
      'History and Literature of the New Testament Era',
    ],
    'Social StudiesDisplayNames': {
      'Kindergarten': 'Kindergarten Social Studies',
      '1st Grade': '1st Grade Social Studies',
      '2nd Grade': '2nd Grade Social Studies',
      '3rd Grade': '3rd Grade Social Studies',
      '4th Grade': '4th Grade Social Studies',
      '5th Grade': '5th Grade Social Studies',
      '6th Grade': '6th Grade Social Studies',
      '7th Grade': '7th Grade Social Studies',
      '8th Grade': '8th Grade Social Studies',
      'Economics and Personal Finance': 'Economics and Personal Finance',
      'Human Geography': 'Human Geography',
      'Modern World History': 'Modern World History',
      'History and Literature of the Old Testament Era': 'History and Literature of the Old Testament Era',
      'History and Literature of the New Testament Era': 'History and Literature of the New Testament Era',
      'United States Government': 'United States Government',
      'United States History and the Constitution': 'United States History and the Constitution'
    },
    'Science': [
      'Kindergarten',
      '1st Grade',
      '2nd Grade',
      '3rd Grade',
      '4th Grade',
      '5th Grade',
      '6th Grade',
      '7th Grade',
      '8th Grade',
      'Biology',
      'Chemistry',
      'Earth and Space Science',
      'Physics',
    ],
    'ScienceDisplayNames': {
      'Kindergarten': 'Kindergarten Science',
      '1st Grade': '1st Grade Science',
      '2nd Grade': '2nd Grade Science',
      '3rd Grade': '3rd Grade Science',
      '4th Grade': '4th Grade Science',
      '5th Grade': '5th Grade Science',
      '6th Grade': '6th Grade Science',
      '7th Grade': '7th Grade Science',
      '8th Grade': '8th Grade Science',
      'Biology': 'Biology',
      'Chemistry': 'Chemistry',
      'Earth and Space Science': 'Earth and Space Science',
      'Physics': 'Physics',
    }
  }
}


export function getTierCircleColor(iep) {
  let circleColor = "#08979c"
  if (iep.hasOwnProperty("isMTSS") && iep.isMTSS) {
    if (iep.iep.tier === "Tier 2") {
      circleColor = "#d48806"
    } else {
      circleColor = "#cf1322"
    }
  }

  return circleColor
}

export function setCharAt(s, n, t) {
  return s.substring(0, n) + t + s.substring(n + 1)
}

export function getTotalPoints(iep) {
  var total = 0
  iep.progressMonitoring.map((item, index) => {
    return total += item.num
  })

  return total
}

export function getTotalPointsBaselineOrTarget(pmObj) {
  var total = 0
  pmObj.map((item, index) => {
    return total += item.num
  })

  return total
}

export function getStudentMayHaveTroubleWith() {
  return {
    information: 'processing ideas and information efficiently',
    vocabulary: 'understanding ideas and information',
    problemSolving: 'remembering and focusing on the most important ideas and information',
    efficiency: 'applying ideas and information in new situations',
    ell: 'visualizing connections and organizing ideas and information'
  }
}

// antd needs steps to just be html, couldn't create
// a react component for this one.
export function stepLink(title, icon, status, href, state) {
  return <Step
    status={status ? status : ''}
    title={
      <Link to={
        {
          pathname: href ? href : '',
          state: state ? state : {},
        }
      }
        className=""
      >
        <Tooltip title="Go to this step." placement="top">
          <div className={"ant-steps-item ant-steps-item-finish " +
            "ant-steps-item-custom"}>
            <div className="ant-steps-item-icon">
              <span className="ant-steps-icon">
                {icon ? icon : ''}
              </span>
            </div>
            <div className="ant-steps-item-content">
              <div className="ant-steps-item-title">
                {title ? title : ''}
              </div>
            </div>
          </div>
        </Tooltip>
      </Link>
    }
    icon={<span></span>}
  >
  </Step>
}

export function capitalizeFirstChar(stringToCapitalize) {
  return stringToCapitalize.charAt(0).toUpperCase() +
    stringToCapitalize.slice(1).toLowerCase()
}

export function calendarBlockKey(momentObj) {
  return momentObj.format('DD-HH-mm')
}

export function getCalendarBlock() {
  var calendarBlock = {}
  var timeStep = 30      // minutes
  var timeRatio = 60 / 30 // hours / minutes
  var hours = 10          // time duration from start to end (7 AM - 5 PM)
  var mondayStart = moment(new Date(2015, 5, 1, 7, 0, 0))
  var tuesdayStart = moment(new Date(2015, 5, 2, 7, 0, 0))
  var wednesdayStart = moment(new Date(2015, 5, 3, 7, 0, 0))
  var thursdayStart = moment(new Date(2015, 5, 4, 7, 0, 0))
  var fridayStart = moment(new Date(2015, 5, 5, 7, 0, 0))

  for (var i = 0; i <= timeRatio * hours; i++) {
    var newMondayTime = moment(mondayStart)
    var newTuesdayTime = moment(tuesdayStart)
    var newWednesdayTime = moment(wednesdayStart)
    var newThursdayTime = moment(thursdayStart)
    var newFridayTime = moment(fridayStart)

    var newMondayString = calendarBlockKey(newMondayTime)
    var newTuesdayString = calendarBlockKey(moment(newTuesdayTime))
    var newWednesdayString = calendarBlockKey(moment(newWednesdayTime))
    var newThursdayString = calendarBlockKey(moment(newThursdayTime))
    var newFridayString = calendarBlockKey(moment(newFridayTime))

    // calendarBlock for each timeSlot is the number of people currently
    // scheduled in that block, intialized to 0.
    calendarBlock[newMondayString] = { 'General Education': 0, 'Special Education': 0 }
    calendarBlock[newTuesdayString] = { 'General Education': 0, 'Special Education': 0 }
    calendarBlock[newWednesdayString] = { 'General Education': 0, 'Special Education': 0 }
    calendarBlock[newThursdayString] = { 'General Education': 0, 'Special Education': 0 }
    calendarBlock[newFridayString] = { 'General Education': 0, 'Special Education': 0 }

    mondayStart = mondayStart.add(timeStep, "minutes")
    tuesdayStart = tuesdayStart.add(timeStep, "minutes")
    wednesdayStart = wednesdayStart.add(timeStep, "minutes")
    thursdayStart = thursdayStart.add(timeStep, "minutes")
    fridayStart = fridayStart.add(timeStep, "minutes")
  }

  return calendarBlock
}

export function getEmptySchoolOrDistrictSummary() {
  var data = []

  for (var i = 0; i < 4116; ++i) {
    data.push(0)
  }

  return data
  /**var racesOrEthnicities = ["all", "Native American or Alaska Native", 
  "Asian", "Black or African American",
  "Native Hawaiian or Other Pacific Islander",
  "White", "Hispanic or Latino"]
  racesOrEthnicities =  ["a", "NA", 
  "As", "B",
  "NH",
  "W", "H"]
  var grades = [
    'a',
    'K',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ]
  var subjects = [
    'all',
    'Writing',
    'Reading Comprehension in Literature',
    'Reading Comprehension in Informational Text',
    'Reading Foundations',
    'Math',
    'Social Emotional Learning',
  ]
  subjects = [
    'a',
    'W',
    'RL',
    'RI',
    'RF',
    'M',
    'S',
  ]
  var supportLevels = [
    'h',
    'm',
    'l',
  ]
  /**var iepInfos = [
    'total',
  ]
  var iepWithMeasurementInfos = [
    'total',
    'onTrack',
  ]

  var schoolObj = {}
  grades.forEach((grade, index) => {
    subjects.forEach((subject, index) => {
      schoolObj[grade + '-' + subject] = []
      racesOrEthnicities.forEach((race, index) => {
        schoolObj[grade + '-' + subject].push(0)
        schoolObj[grade + '-' + subject].push(0)
        schoolObj[grade + '-' + subject].push(0)
        //schoolObj[grade + '-' + subject + '-' + race + '-t'] = 0
        //schoolObj[grade + '-' + subject + '-' + race + '-wt'] = 0
        //schoolObj[grade + '-' + subject + '-' + race + '-wot'] = 0
        supportLevels.forEach((supportLevel, index) => {
          schoolObj[grade + '-' + subject].push(0)
          //schoolObj[grade][subject][race] = {}
          //schoolObj[grade][subject][race]['withMeasurements'] = {}
         // schoolObj[grade + '-' + subject + '-' + race + '-' + supportLevel + '-t'] = 0
        })
      
        iepInfos.forEach((iepInfo, index) => {
          schoolObj[grade][subject][race][iepInfo] = 0
        })
        iepWithMeasurementInfos.forEach((iepWithMeasurementInfo, index) => {
          schoolObj[grade][subject][race]['withMeasurements'][iepWithMeasurementInfo] = 0
        })
      })
    })
  })

  return schoolObj*/
}

export function levelToSupport(level) {
  if (level === '70 and below') return 'h'
  else if (level === '70-79') return 'm'
  else if (level === '80 and above') return 'l'
  console.log("Level was not found!", level)
  return 'h'
}

export function incrementSummary(iep, schoolOrDistrict, grade,
  subject, raceOrEthnicity, school, isDistrict) {
  // I don't think this matters as its just a reference to schoolOrDistrict but am doing it for
  // clarity reasons.
  var updatedSchoolOrDistrict = schoolOrDistrict

  // add school obj with key school.id if the school id isn't already in the schoolSummary
  if (isDistrict &&
    updatedSchoolOrDistrict.summary.schoolSummary.hasOwnProperty(school.schoolType)) {
    if (!updatedSchoolOrDistrict.summary
      .schoolSummary[school.schoolType].hasOwnProperty(school.id)) {
      updatedSchoolOrDistrict.summary.schoolSummary[school.schoolType][school.id] = {
        schoolId: school.id,
        schoolName: school.schoolName,
        total: 0,
        totalWithMeasurements: 0,
        onTrack: 0,
      }
    }

    updatedSchoolOrDistrict.summary.schoolSummary[school.schoolType][school.id].total += 1
  }

  // update all fields
  var gradeKeys = [grade, 'all']
  var subjectKeys = [subject, 'all']
  var raceOrEthnicityKeys = [raceOrEthnicity, 'all']
  gradeKeys.forEach((gradeKey, index) => {
    subjectKeys.forEach((subjectKey, index) => {
      raceOrEthnicityKeys.forEach((raceOrEthnicityKey, index) => {
        // increment total
        var idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'total')
        if (idx === null) console.log("idx is null", idx)
        if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] += 1
        // increment support level
        idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, levelToSupport(iep.level))
        if (idx === null) console.log("idx is null", idx)
        if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] += 1
      })
    })
  })

  return updatedSchoolOrDistrict
}

export function updateSummary(docBefore, docAfter,
  schoolOrDistrict, grade, subject, raceOrEthnicity, school, isDistrict) {
  // I don't think this matters as its just a reference to schoolOrDistrict but am doing it for
  // clarity reasons.
  var updatedSchoolOrDistrict = schoolOrDistrict
  if (isDistrict) console.log(schoolOrDistrict, school.schoolType, school.id, school.schoolName)

  // update school in district schoolSummary
  if (isDistrict &&
    updatedSchoolOrDistrict.summary.schoolSummary.hasOwnProperty(school.schoolType)) {
    // Need to check if this district shard has the school.id in its schoolSummary.
    // Because a random shard calls incrementSummary(...), 18 shards are left that
    // don't have this data initialized. So check, and initialize if this shard
    // does not have the school.id in its schoolSummary
    if (!updatedSchoolOrDistrict.summary
      .schoolSummary[school.schoolType].hasOwnProperty(school.id)) {
      updatedSchoolOrDistrict.summary.schoolSummary[school.schoolType][school.id] = {
        schoolId: school.id,
        schoolName: school.schoolName,
        total: 0,
        totalWithMeasurements: 0,
        onTrack: 0,
      }
    }

    if (docBefore.totalMeasurements === 0 && docAfter.totalMeasurements > 0) {
      updatedSchoolOrDistrict.summary
        .schoolSummary[school.schoolType][school.id].totalWithMeasurements += 1
    }
    else if (docBefore.totalMeasurements > 0 && docAfter.totalMeasurements === 0) {
      updatedSchoolOrDistrict.summary
        .schoolSummary[school.schoolType][school.id].totalWithMeasurements -= 1
    }
    if (!docBefore.onTrack && docAfter.onTrack) {
      updatedSchoolOrDistrict.summary
        .schoolSummary[school.schoolType][school.id].onTrack += 1
    }
    else if (docBefore.onTrack && !docAfter.onTrack) {
      updatedSchoolOrDistrict.summary
        .schoolSummary[school.schoolType][school.id].onTrack -= 1
    }
  }

  // update all fields
  var gradeKeys = [grade, 'all']
  var subjectKeys = [subject, 'all']
  var raceOrEthnicityKeys = [raceOrEthnicity, 'all']
  gradeKeys.forEach((gradeKey, index) => {
    subjectKeys.forEach((subjectKey, index) => {
      raceOrEthnicityKeys.forEach((raceOrEthnicityKey, index) => {
        // inc total if before total measurements was 0 and after total was > 0
        // (i.e. iep goal now has measurements)
        if (docBefore.totalMeasurements === 0 && docAfter.totalMeasurements > 0) {
          var idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'totalWithMeasurements')
          if (idx === null) console.log("idx is null", idx)
          if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] += 1
        }
        // dec total if before was > 0 and after total was 0 (i.e. iep goal has no measurements).
        // can be less than 0 because all shards will be summed and the sum will be >= 0.
        else if (docBefore.totalMeasurements > 0 && docAfter.totalMeasurements === 0) {
          idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'totalWithMeasurements')
          if (idx === null) console.log("idx is null", idx)
          if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] -= 1
        }
        // inc onTrack if before onTrack was false and after onTrack was true.
        if (!docBefore.onTrack && docAfter.onTrack) {
          idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'onTrack')
          if (idx === null) console.log("idx is null", idx)
          if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] += 1
        }
        // dec onTrack if before onTrack was true and after onTrack was falee,
        // can be less than 0 because all shards will be summed and the sum will be >= 0.
        else if (docBefore.onTrack && !docAfter.onTrack) {
          idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'onTrack')
          if (idx === null) console.log("idx is null", idx)
          if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] -= 1
        }
      })
    })
  })

  return updatedSchoolOrDistrict
}

export function decrementSummary(docToBeDeleted, iep, schoolOrDistrict, grade,
  subject, raceOrEthnicity, school, isDistrict) {
  // I don't think this matters as its just a reference to schoolOrDistrict but am doing it for
  // clarity reasons.
  var updatedSchoolOrDistrict = schoolOrDistrict

  // add school obj with key school.id if the school id isn't already in the schoolSummary
  // This is used for the school bar charts on the district summary page only
  if (isDistrict &&
    updatedSchoolOrDistrict.summary.schoolSummary.hasOwnProperty(school.schoolType)) {
    if (!updatedSchoolOrDistrict.summary
      .schoolSummary[school.schoolType].hasOwnProperty(school.id)) {
      updatedSchoolOrDistrict.summary.schoolSummary[school.schoolType][school.id] = {
        schoolId: school.id,
        schoolName: school.schoolName,
        total: 0,
        totalWithMeasurements: 0,
        onTrack: 0,
      }
    }

    // dec total
    updatedSchoolOrDistrict.summary.schoolSummary[school.schoolType][school.id].total -= 1

    // if the deleted iep goal had measurements, then dec totalWithMeasurements
    if (docToBeDeleted.totalMeasurements > 0) {
      updatedSchoolOrDistrict.summary
        .schoolSummary[school.schoolType][school.id].totalWithMeasurements -= 1
    }
    // if the deleted iep goal was onTrack, then dec onTrack
    if (docToBeDeleted.onTrack) {
      updatedSchoolOrDistrict.summary
        .schoolSummary[school.schoolType][school.id].onTrack -= 1
    }
  }

  // update all fields
  var gradeKeys = [grade, 'all']
  var subjectKeys = [subject, 'all']
  var raceOrEthnicityKeys = [raceOrEthnicity, 'all']
  gradeKeys.forEach((gradeKey, index) => {
    subjectKeys.forEach((subjectKey, index) => {
      raceOrEthnicityKeys.forEach((raceOrEthnicityKey, index) => {
        // dec total
        var idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'total')
        if (idx === null) console.log("idx is null", idx)
        if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] -= 1

        // dec support level
        idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, levelToSupport(iep.level))
        if (idx === null) console.log("idx is null", idx)
        if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] -= 1

        // if the deleted iep goal had measurements, then dec totalWithMeasurements
        if (docToBeDeleted.totalMeasurements > 0) {
          idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'totalWithMeasurements')
          if (idx === null) console.log("idx is null", idx)
          if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] -= 1
        }

        // if the deleted iep goal was onTrack, then dec onTrack
        if (docToBeDeleted.onTrack) {
          idx = summaryIndex(gradeKey, subjectKey, raceOrEthnicityKey, 'onTrack')
          if (idx === null) console.log("idx is null", idx)
          if (idx !== null) updatedSchoolOrDistrict.summary.summary[idx] -= 1
        }
      })
    })
  })

  return updatedSchoolOrDistrict
}

export function leastSquares(xSeries, ySeries) {
  if (xSeries.length === 0 || ySeries.length === 0) return [0, 0, 0]

  var reduceSumFunc = function (prev, cur) { return prev + cur }

  var xBar = xSeries.reduce(reduceSumFunc) * 1.0 / xSeries.length
  var yBar = ySeries.reduce(reduceSumFunc) * 1.0 / ySeries.length

  var ssXX = xSeries.map(function (d) { return Math.pow(d - xBar, 2) })
    .reduce(reduceSumFunc)

  var ssYY = ySeries.map(function (d) { return Math.pow(d - yBar, 2) })
    .reduce(reduceSumFunc)

  var ssXY = xSeries.map(function (d, i) { return (d - xBar) * (ySeries[i] - yBar) })
    .reduce(reduceSumFunc)

  var slope = ssXY / ssXX
  var intercept = yBar - (xBar * slope)
  var rSquare = Math.pow(ssXY, 2) / (ssXX * ssYY)

  return [slope, intercept, rSquare]
}

export function isGoalOnTrack(iep, measurements) {
  if (measurements.length === 0) return false
  if (measurements.length > 18) {
    if (parseInt(measurements[measurements.length - 1], 10) >= iep.targetAccuracyLevel) {
      return true
    }
    else {
      return false
    }
  }

  var xData = [0]
  var yData = [parseInt(iep.baselineAccuracyLevel, 10)]

  measurements.map((item, index) => {
    xData.push(index + 1)
    yData.push(parseInt(item.measurement, 10))
    return false
  })

  var leastSquaresRes = leastSquares(xData, yData)
  // 19 becuase baseline accuracy is at index 0
  var finalYValue = (19) * leastSquaresRes[0] + leastSquaresRes[1]

  // if the finalYValue is greater than or equal to the target accuracy level this
  // goal is on track, else its not on track
  if (finalYValue >= iep.targetAccuracyLevel) return true
  else return false
}

export function getNumShards() {
  return 20
}

export function getRandomShardIndex(numShards) {
  return Math.floor(Math.random() * numShards - 1) + 1
}

export function compress(data) {
  return LZString.compressToEncodedURIComponent(JSON.stringify(data))
}

export function decompress(data) {
  data = LZString.decompressFromEncodedURIComponent(data)
  //console.log("util decompress", data)
  //data.summary = data.summary.split(',').map(Number)
  return JSON.parse(data)
}

export function summaryIndex(grade, subject, race, support) {
  var grades = ['all', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  var subjects = ['all', 'Writing', 'Reading Comprehension in Literature',
    'Reading Comprehension in Informational Text', 'Reading Foundations',
    'Math', 'Social Emotional Learning']
  var racesOrEthnicities = ['all', 'Native American or Alaska Native', 'Asian',
    'Black or African American',
    'Native Hawaiian or Other Pacific Islander',
    'White', 'Hispanic or Latino']
  var supportLevels = ['h', 'm', 'l', 'total', 'totalWithMeasurements', 'onTrack']
  var a = grades.indexOf(grade)
  if (a === -1) return null
  var B = subjects.length
  var b = subjects.indexOf(subject)
  if (b === -1) return null
  var C = racesOrEthnicities.length
  var c = racesOrEthnicities.indexOf(race)
  if (c === -1) return null
  var D = supportLevels.length
  var d = supportLevels.indexOf(support)
  if (d === -1) return null

  return (a * B * C * D) + (b * C * D) + (c * D) + d
}

// in place modifying a, function is not pure, takes a, b and modifies a
// then returns a. Doing it because I don't want to create another 30kb array.
export function addArrays(a, b) {
  if (a.length !== b.length) {
    console.log("Util addArrays arrays were different lengths a.length:",
      a.length, "b.length:", b.length)
    return
  }

  for (var i = 0; i < a.length; i++) {
    a[i] += b[i]
  }

  return a
}

export function openAwardNotification(message, description) {
  notification.open({
    duration: 8,
    message: message,
    description: description,
    className: "notification-award",
    icon: <img src="/svgs/confetti.png" alt="img" height="60" />,
    placement: 'bottomRight'
  })
}

function createInitialTeacherStatsObj(teacher) {
  return {
    teacherId: teacher.id,
    schoolId: teacher.schoolId,
    districtId: teacher.districtId,
    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    exp: 0,
    numStudents: 0,
    numGoals: 0,
    numMeasurements: 0,
    numCalendarEvents: 0,
    numNotes: 0,
  }
}

export function awardsIncStudents(teacher, incNumStudentsValue) {
  // no students to add
  if (incNumStudentsValue <= 0) {
    return
  }

  var statPromise = new Promise((resolve, reject) => {
    if (!teacher) {
      reject()
    }
    console.log("awardsIncStudents", teacher)
    var addStudentExp = 10
    db.collection(ColType.teacherStats)
      .where('teacherId', '==', teacher.id)
      .where('schoolId', '==', teacher.schoolId)
      .where('districtId', '==', teacher.districtId)
      .get()
      .then((querySnapshot) => {
        var docs = querySnapshot.docs

        // if no doc, create it
        if (docs.length === 0) {
          var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
          var numStudents = incNumStudentsValue
          var exp = addStudentExp
          initialTeacherStatsObj.numStudents = numStudents
          initialTeacherStatsObj.exp = exp
          db.collection(ColType.teacherStats)
            .add(initialTeacherStatsObj)
            .then(() => {
              checkAwardNotification({
                numStudents: numStudents,
              })
              resolve()
            })
            .catch((e) => {
              console.log("teacher stats inc student error", e)
              reject(e)
            })
        }
        // else inc it
        else if (docs.length > 0) {
          // flattenDoc will add the id, which is the teacherId, to the doc
          // but I don't think it really matters, this is simplest.
          var teacherStats = flattenDoc(docs[0])
          var previousLevelInfo = getCurrentLevel(teacherStats.exp)
          var previousLevel = previousLevelInfo.level
          numStudents = teacherStats.numStudents + incNumStudentsValue
          exp = teacherStats.exp + addStudentExp
          var newLevelInfo = getCurrentLevel(exp)
          var newLevel = newLevelInfo.level
          db.collection(ColType.teacherStats)
            .doc(teacherStats.id)
            .update({
              numStudents: numStudents,
              exp: exp,
            })
            .then(() => {
              checkAwardNotification({
                numStudents: numStudents,
                previousLevel: previousLevel,
                newLevel: newLevel,
              })
              resolve()
            })
            .catch((e) => {
              console.log("teacher stats inc student error", e)
              reject(e)
            })
        }
      })
      .catch((e) => {
        console.log("teacher stats inc student error", e)
        reject(e)
      })
  })

  return statPromise
}

export function awardsIncMeasurements(teacher) {
  if (!teacher) return
  console.log("awardsIncStudents", teacher)
  var addMeasurementExp = 10
  db.collection(ColType.teacherStats)
    .where('teacherId', '==', teacher.id)
    .where('schoolId', '==', teacher.schoolId)
    .where('districtId', '==', teacher.districtId)
    .get()
    .then((querySnapshot) => {
      var docs = querySnapshot.docs

      // if no doc, create it
      if (docs.length === 0) {
        var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
        var numMeasurements = 1
        var exp = addMeasurementExp
        initialTeacherStatsObj.numMeasurements = numMeasurements
        initialTeacherStatsObj.exp = exp
        db.collection(ColType.teacherStats)
          .add(initialTeacherStatsObj)
          .then(() => {
            checkAwardNotification({
              numMeasurements: numMeasurements,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
      // else inc it
      else if (docs.length > 0) {
        // flattenDoc will add the id, which is the teacherId, to the doc
        // but I don't think it really matters, this is simplest.
        var teacherStats = flattenDoc(docs[0])
        var previousLevelInfo = getCurrentLevel(teacherStats.exp)
        var previousLevel = previousLevelInfo.level
        numMeasurements = teacherStats.numMeasurements + 1
        exp = teacherStats.exp + addMeasurementExp
        var newLevelInfo = getCurrentLevel(exp)
        var newLevel = newLevelInfo.level
        db.collection(ColType.teacherStats)
          .doc(teacherStats.id)
          .update({
            numMeasurements: numMeasurements,
            exp: exp,
          })
          .then(() => {
            checkAwardNotification({
              numMeasurements: numMeasurements,
              previousLevel: previousLevel,
              newLevel: newLevel,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
    })
    .catch((e) => {
      console.log("teacher stats inc student error", e)
    })
}

export function awardsIncNotes(teacher) {
  if (!teacher) return
  console.log("awardsIncStudents", teacher)
  var addNoteExp = 10
  db.collection(ColType.teacherStats)
    .where('teacherId', '==', teacher.id)
    .where('schoolId', '==', teacher.schoolId)
    .where('districtId', '==', teacher.districtId)
    .get()
    .then((querySnapshot) => {
      var docs = querySnapshot.docs

      // if no doc, create it
      if (docs.length === 0) {
        var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
        var numNotes = 1
        var exp = addNoteExp
        initialTeacherStatsObj.numNotes = numNotes
        initialTeacherStatsObj.exp = exp
        db.collection(ColType.teacherStats)
          .add(initialTeacherStatsObj)
          .then(() => {
            checkAwardNotification({
              numNotes: numNotes,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
      // else inc it
      else if (docs.length > 0) {
        // flattenDoc will add the id, which is the teacherId, to the doc
        // but I don't think it really matters, this is simplest.
        var teacherStats = flattenDoc(docs[0])
        var previousLevelInfo = getCurrentLevel(teacherStats.exp)
        var previousLevel = previousLevelInfo.level
        numNotes = teacherStats.numNotes + 1
        exp = teacherStats.exp + addNoteExp
        var newLevelInfo = getCurrentLevel(exp)
        var newLevel = newLevelInfo.level
        db.collection(ColType.teacherStats)
          .doc(teacherStats.id)
          .update({
            numNotes: numNotes,
            exp: exp,
          })
          .then(() => {
            checkAwardNotification({
              numNotes: numNotes,
              previousLevel: previousLevel,
              newLevel: newLevel,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
    })
    .catch((e) => {
      console.log("teacher stats inc student error", e)
    })
}

export function awardsIncCalendarEvents(teacher) {
  if (!teacher) return
  console.log("awardsIncCalendarEvents", teacher)
  var addCalendarEventsExp = 10
  db.collection(ColType.teacherStats)
    .where('teacherId', '==', teacher.id)
    .where('schoolId', '==', teacher.schoolId)
    .where('districtId', '==', teacher.districtId)
    .get()
    .then((querySnapshot) => {
      var docs = querySnapshot.docs

      // if no doc, create it
      if (docs.length === 0) {
        var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
        var numCalendarEvents = 1
        var exp = addCalendarEventsExp
        initialTeacherStatsObj.numCalendarEvents = numCalendarEvents
        initialTeacherStatsObj.exp = exp
        db.collection(ColType.teacherStats)
          .add(initialTeacherStatsObj)
          .then(() => {
            checkAwardNotification({
              numCalendarEvents: numCalendarEvents,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
      // else inc it
      else if (docs.length > 0) {
        // flattenDoc will add the id, which is the teacherId, to the doc
        // but I don't think it really matters, this is simplest.
        var teacherStats = flattenDoc(docs[0])
        var previousLevelInfo = getCurrentLevel(teacherStats.exp)
        var previousLevel = previousLevelInfo.level
        numCalendarEvents = teacherStats.numCalendarEvents + 1
        exp = teacherStats.exp + addCalendarEventsExp
        var newLevelInfo = getCurrentLevel(exp)
        var newLevel = newLevelInfo.level
        db.collection(ColType.teacherStats)
          .doc(teacherStats.id)
          .update({
            numCalendarEvents: numCalendarEvents,
            exp: exp,
          })
          .then(() => {
            checkAwardNotification({
              numCalendarEvents: numCalendarEvents,
              previousLevel: previousLevel,
              newLevel: newLevel,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
    })
    .catch((e) => {
      console.log("teacher stats inc student error", e)
    })
}

export function addStudentOnboardTeacherStats(teacher) {
  return new Promise((resolve, reject) => {
    if (!teacher) {
      reject()
      return
    }

    db.collection(ColType.teacherStats)
      .where('teacherId', '==', teacher.id)
      .where('schoolId', '==', teacher.schoolId)
      .where('districtId', '==', teacher.districtId)
      .get()
      .then((querySnapshot) => {
        var docs = querySnapshot.docs

        // if no doc, create it
        if (docs.length === 0) {
          var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
          initialTeacherStatsObj.onboardAddStudentShown = true
          db.collection(ColType.teacherStats)
            .add(initialTeacherStatsObj)
            .then(() => {
              console.log("teacher stats add student onboard status saved")
              resolve()
            })
            .catch((e) => {
              console.log("teacher stats inc student error", e)
              reject(e)
            })
        } else {
          var teacherStats = flattenDoc(docs[0])
          db.collection(ColType.teacherStats)
            .doc(teacherStats.id)
            .update({
              onboardAddStudentShown: true
            })
            .then(() => {
              console.log("teacher stats existing object add student onboard status saved")
              resolve()
            })
            .catch((e) => {
              console.log("teacher stats inc student error", e)
              reject(e)
            })
        }
      })
  })
}


export function draftPlanOnboardTeacherStats(teacher) {
  return new Promise((resolve, reject) => {
    if (!teacher) {
      reject()
      return
    }

    db.collection(ColType.teacherStats)
      .where('teacherId', '==', teacher.id)
      .where('schoolId', '==', teacher.schoolId)
      .where('districtId', '==', teacher.districtId)
      .get()
      .then((querySnapshot) => {
        var docs = querySnapshot.docs

        // if no doc, create it
        if (docs.length === 0) {
          var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
          initialTeacherStatsObj.onboardDraftPlanShown = true
          db.collection(ColType.teacherStats)
            .add(initialTeacherStatsObj)
            .then(() => {
              console.log("teacher stats add student onboard status saved")
              resolve()
            })
            .catch((e) => {
              console.log("teacher stats inc student error", e)
              reject(e)
            })
        } else {
          var teacherStats = flattenDoc(docs[0])
          db.collection(ColType.teacherStats)
            .doc(teacherStats.id)
            .update({
              onboardDraftPlanShown: true
            })
            .then(() => {
              console.log("teacher stats existing object add student onboard status saved")
              resolve()
            })
            .catch((e) => {
              console.log("teacher stats inc student error", e)
              reject(e)
            })
        }
      })
  })
}

export function awardsIncGoals(teacher, addedNumGoals) {
  if (!teacher) return
  console.log("awardsIncGoals", teacher, addedNumGoals)
  var addGoalExp = 10
  db.collection(ColType.teacherStats)
    .where('teacherId', '==', teacher.id)
    .where('schoolId', '==', teacher.schoolId)
    .where('districtId', '==', teacher.districtId)
    .get()
    .then((querySnapshot) => {
      var docs = querySnapshot.docs

      // if no doc, create it
      if (docs.length === 0) {
        var initialTeacherStatsObj = createInitialTeacherStatsObj(teacher)
        // goals is the only place you have to check for level ups on
        // the empty doc case, becuase they can add n goals and level up
        // potentially
        var previousLevel = 1
        var numGoals = addedNumGoals
        var exp = addGoalExp * addedNumGoals
        var newLevelInfo = getCurrentLevel(exp)
        var newLevel = newLevelInfo.level
        initialTeacherStatsObj.numGoals = addedNumGoals
        initialTeacherStatsObj.exp = exp
        db.collection(ColType.teacherStats)
          .add(initialTeacherStatsObj)
          .then(() => {
            checkAwardNotification({
              previousNumGoals: 0,
              numGoals: numGoals,
              previousLevel: previousLevel,
              newLevel: newLevel,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
      // else inc it
      else if (docs.length > 0) {
        // flattenDoc will add the id, which is the teacherId, to the doc
        // but I don't think it really matters, this is simplest.
        var teacherStats = flattenDoc(docs[0])
        var previousLevelInfo = getCurrentLevel(teacherStats.exp)
        previousLevel = previousLevelInfo.level
        var previousNumGoals = teacherStats.numGoals
        numGoals = teacherStats.numGoals + addedNumGoals
        console.log("new num goals", numGoals)
        exp = teacherStats.exp + (addGoalExp * addedNumGoals)
        newLevelInfo = getCurrentLevel(exp)
        newLevel = newLevelInfo.level
        db.collection(ColType.teacherStats)
          .doc(teacherStats.id)
          .update({
            numGoals: numGoals,
            exp: exp,
          })
          .then(() => {
            checkAwardNotification({
              previousNumGoals: previousNumGoals,
              numGoals: numGoals,
              previousLevel: previousLevel,
              newLevel: newLevel,
            })
          })
          .catch((e) => {
            console.log("teacher stats inc student error", e)
          })
      }
    })
    .catch((e) => {
      console.log("teacher stats inc student error", e)
    })
}

function checkAwardNotification(statsDiffObj) {
  // exp is total, not diffed, everything else is diffed
  if (statsDiffObj.hasOwnProperty("previousLevel") &&
    statsDiffObj.hasOwnProperty("newLevel")) {
    var previousLevel = statsDiffObj["previousLevel"]
    var newLevel = statsDiffObj["newLevel"]
    if (previousLevel !== newLevel) {
      /*
        openAwardNotification("Hooray!", 
        "You leveled up to level " + newLevel + "! Congratulations!")
      */
    }
  }
  if (statsDiffObj.hasOwnProperty("numStudents")) {
    var numStudents = statsDiffObj["numStudents"]
    if (numStudents === 1) {
      openAwardNotification("Hooray!",
        "You have added your first student! Great job!")
    }
    else if (numStudents % 5 === 0 || (numStudents === 5 || numStudents === 15 ||
      numStudents === 30 || numStudents === 50 ||
      numStudents === 100)) {
      openAwardNotification("Hooray!",
        "You have added " + numStudents + " students! Great job!")
    }
  }
  if (statsDiffObj.hasOwnProperty("numMeasurements")) {
    var numMeasurements = statsDiffObj["numMeasurements"]
    if (numMeasurements === 1) {
      openAwardNotification("Hooray!",
        "You have added your first progress monitoring measurement! " +
        "Keep up the good work!")
    }
    else if (numMeasurements % 5 === 0 || (numMeasurements === 5 || numMeasurements === 15 ||
      numMeasurements === 30 || numMeasurements === 50 ||
      numMeasurements === 100 || numMeasurements === 200 ||
      numMeasurements === 500)) {
      openAwardNotification("Hooray!",
        "You have added " + numMeasurements + " progress monitoring " +
        "measurements! Keep up the good work!")
    }
  }
  if (statsDiffObj.hasOwnProperty("numNotes")) {
    var numNotes = statsDiffObj["numNotes"]
    if (numNotes === 1) {
      openAwardNotification("Hooray!",
        "You have added your first note! Well done!")
    }
    else if (numNotes % 5 === 0 || (numNotes === 5 || numNotes === 15 ||
      numNotes === 30 || numNotes === 50 ||
      numNotes === 100 || numNotes === 200 ||
      numNotes === 500)) {
      openAwardNotification("Hooray!",
        "You have added " + numNotes + " notes! Well done!")
    }
  }
  if (statsDiffObj.hasOwnProperty("numCalendarEvents")) {
    var numCalendarEvents = statsDiffObj["numCalendarEvents"]
    if (numCalendarEvents === 1) {
      openAwardNotification("Hooray!",
        "You have added your first calendar event! Great job!")
    }
    else if (numCalendarEvents % 10 === 0 || (numCalendarEvents === 5 || numCalendarEvents === 15 ||
      numCalendarEvents === 30 || numCalendarEvents === 50 ||
      numCalendarEvents === 100 || numCalendarEvents === 200 ||
      numCalendarEvents === 500)) {
      openAwardNotification("Hooray!",
        "You have added " + numCalendarEvents + " calendar events! " +
        "Great job!")
    }
  }
  if (statsDiffObj.hasOwnProperty("numGoals") &&
    statsDiffObj.hasOwnProperty("previousNumGoals")) {
    var previousNumGoals = statsDiffObj["previousNumGoals"]
    var numGoals = statsDiffObj["numGoals"]
    if (numGoals === 1) {
      openAwardNotification("Hooray!",
        "You have added your first plan! Way to go!")
    } else if (numGoals % 5 === 0) {
      openAwardNotification("Hooray!",
        "You have added " + numGoals + " or more plans! " +
        "Way to go!")
    } else {
      var numGoalArr = [500, 250, 100, 50, 30, 15, 5]
      for (var i = 0; i < numGoalArr.length; i++) {
        var numGoalMarker = numGoalArr[i]
        if (previousNumGoals < numGoalMarker && numGoals >= numGoalMarker) {
          openAwardNotification("Hooray!",
            "You have added " + numGoalMarker + " or more plans! " +
            "Way to go!")
          break
        }
      }
    }
  }
}

function getLevelStartExp(level) {
  var levelInc = 50
  if (level === 1) return 0
  if (level === 2) return level * levelInc
  else {
    return getLevelStartExp(level - 1) + (level * levelInc)
  }
}

function getCurrentLevel(exp) {
  for (var i = 99; i > 0; i--) {
    var levelStartExp = getLevelStartExp(i)
    //console.log(i, exp, levelStartExp, 
    //  levelStartExp - exp, getLevelStartExp(i + 1))
    if (exp >= levelStartExp) {
      return {
        level: i,
        remainingExp: exp - levelStartExp,
        nextLevelExp: getLevelStartExp(i + 1) - levelStartExp
      }
    }
  }
  return {
    level: 99,
    remainingExp: 9999,
    nextLevelExp: 9999,
  }
}

export function getLevelObj(exp) {
  var levelInfo = getCurrentLevel(exp)
  var level = levelInfo.level
  var remainingExp = levelInfo.remainingExp
  var nextLevelExp = levelInfo.nextLevelExp
  var levelName = "Dot It Explorer"
  if (level < 3) levelName = "Dot It Explorer"
  else if (level < 7) levelName = "Dot It Connector"
  else levelName = "Dot it Pro"
  //console.log(exp, level, remainingExp, nextLevelExp, levelName)
  return {
    level: level,
    remainingExp: remainingExp,
    nextLevelExp: nextLevelExp,
    levelName: levelName,
  }
}

export function isSandboxNoSub(teacherBaseProps) {
  // user is sandbox and hasn't bought a subscription yet
  return (teacherBaseProps &&
    teacherBaseProps.user &&
    teacherBaseProps.user.hasOwnProperty("isSandbox") &&
    teacherBaseProps.user.isSandbox &&
    !teacherBaseProps.user.hasOwnProperty("lastSubscriptionTimeStamp") &&
    teacherBaseProps.teacher) ||
    // user is sandbox and has bought a subscription but it has expired
    (teacherBaseProps &&
      teacherBaseProps.user &&
      teacherBaseProps.user.hasOwnProperty("isSandbox") &&
      teacherBaseProps.user.isSandbox &&
      teacherBaseProps.user.hasOwnProperty("lastSubscriptionTimeStamp") &&
      teacherBaseProps.user.hasOwnProperty("subscriptionDaysLeft") &&
      teacherBaseProps.user.subscriptionDaysLeft <= 0 &&
      teacherBaseProps.teacher)
}

export function isSandboxNoGoalsLeft(teacherBaseProps) {
  return teacherBaseProps &&
    teacherBaseProps.user &&
    teacherBaseProps.user.hasOwnProperty("isSandbox") &&
    teacherBaseProps.user.isSandbox &&
    teacherBaseProps.teacherStats &&
    teacherBaseProps.teacherStats.hasOwnProperty("numGoals") &&
    teacherBaseProps.teacherStats.numGoals >= 10
}

export function getLevelColor(level) {
  if (level === "IEP") {
    return "font-cyan"
  } else if (level === "Tier 2") {
    return "font-yellow"
  } else if (level === "Tier 3") {
    return "font-red"
  }
  return "#000"
}

export function getFormattedProgressMonitoringText(iep) {
  var questions = []
  // return blank array of questions (always 4 questions if iep is null)
  if (!iep || iep.iep.studentWill.length !== 11) {
    questions = ['Add a new goal to get access to the updated progress monitoring rubric',
      'Add a new goal to get access to the updated progress monitoring rubric',
      'Add a new goal to get access to the updated progress monitoring rubric',
      'Add a new goal to get access to the updated progress monitoring rubric']

    for (let i = 0; i < iep.iep.progressMonitoring.length; i++) {
      let question = iep.iep.progressMonitoring[i].info
      question = question.charAt(0).toUpperCase() + question.slice(1)
      if (i <= questions.length - 1) {
        questions[i] = question
      }
    }
    return questions
  }

  let isMTSS = iep.hasOwnProperty('isMTSS') && iep.isMTSS
  if (isMTSS) {
    questions.push(iep.iep['given'][2] + " " + iep.iep.studentWill[1])
  } else {
    questions.push(iep.iep.studentWill[0] + " " + iep.iep.studentWill[2])
  }

  if (isMTSS) {
    questions.push(iep.iep.studentWill[4] + " " + iep.iep.studentWill[5])
  } else {
    questions.push(iep.iep.studentWill[5] + " " + iep.iep.studentWill[6])
  }

  if (isMTSS) {
    questions.push(removeLastCommaIfNeeded(iep.iep.studentWill[6]) + " " + iep.iep.studentWill[7])
  } else {
    questions.push(iep.iep.studentWill[7] + " " + iep.iep.studentWill[8])
  }

  // remove 'and' from sentence if needed
  var sentence = iep.iep.studentWill[9]
  if (isMTSS) {
    sentence = iep.iep.studentWill[8]
  }

  if (sentence.length >= 4 && sentence.charAt(0) === 'a' && sentence.charAt(1) === 'n' && sentence.charAt(2) === 'd' && sentence.charAt(3) === ' ') {
    sentence = sentence.substr(4, sentence.length)
  }

  if (isMTSS) {
    questions.push(sentence + " " + iep.iep.studentWill[9])
  } else {
    questions.push(sentence + " " + iep.iep.studentWill[10])
  }

  for (let i = 0; i < questions.length; i++) {
    let question = questions[i]
    // remove trailing comma if needed
    if (question.charAt(question.length - 1) === ',') {
      question = question.substr(0, question.length - 1)
    }
    // capitalize first char without ruining capitalization elswhere in the string
    question = question.charAt(0).toUpperCase() + question.slice(1)
    // strings are immutable in js
    questions[i] = question
  }
  return questions
}

export function getStrengths() {
  return {
    "K2": {
      'Reading Comprehension in Literature': [
        'demonstrating phonemic awareness',
        'understanding and applying phonics',
        'recognizing grade level sight words',
        'reading grade-level texts accurately and fluently',
        'asking and answering questions about key details',
        'identifying main topics and retelling details',
        'connecting individuals, ideas, and events',
        'determining the meaning of key vocabulary',
        'knowing and using text features',
        "determining author's purpose and point of view",
        'evaluating content from diverse media',
        "evaluating the author's claim",
        "comparing and contrasting multiple texts",
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Comprehension in Informational Text': [
        'demonstrating phonemic awareness',
        'understanding and applying phonics',
        'recognizing grade level sight words',
        'reading grade-level texts accurately and fluently',
        'asking and answering questions about key details',
        'identifying main topics and retelling details',
        'connecting individuals, ideas, and events',
        'determining the meaning of key vocabulary',
        'knowing and using text features',
        "determining author's purpose and point of view",
        'evaluating content from diverse media',
        "evaluating the author's claim",
        "comparing and contrasting multiple texts",
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Foundations': [
        'knowing and applying print concepts',
        'recognizing and producing letter names and sounds',
        'distinguishing between words and letters',
        'demonstrating phonological awareness',
        'demonstrating phonemic awareness',
        'understanding and applying phonics',
        'recognizing grade level sight words',
        'reading words with irregular spellings',
        'reading grade-level texts accurately and fluently',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Writing': [
        'making letter like forms',
        'stringing letters together',
        "writing one's name",
        'developing an idea for a written opinion',
        'identifying a topic to write about',
        'identifying a real or imagined event to write about',
        'formulating a written opinion with supporting reasons',
        'identifying a topic to write about with supportive facts',
        'writing about a real or imagined event in correct sequence using details',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Social Emotional Learning': [
        'identifying and managing emotions',
        'recognizing personal traits and supports',
        'demonstrating skills for achieving goals',
        'recognizing the feelings and perspectives of others',
        'recognizing group similarities and differences',
        'communicating and interacting effectively with others',
        'preventing and resolving interpersonal conflicts',
        'making ethical, safe, responsible decisions',
        'applying decision making skills in school situations',
        'contributing to the school and community',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Math': [
        'counting and knowing which quantity is larger or smaller',
        'representing problems using objects, pictures, and numerals',
        'understanding and using properties',
        'performing arithmetic accurately and fluently',
        'understanding and using place value',
        'understanding and working with equations',
        'performing measurement tasks',
        'representing and interpreting data',
        'solving real world measurement problems',
        'understanding and working with shapes',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ]
    },
    "35": {
      'Reading Comprehension in Literature': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        'demonstrating understanding of key ideas/details and drawing inferences',
        'determining the main idea/theme and summarizing',
        'describing interactions between people, ideas, and events',
        'determining the meaning of key vocabulary',
        'analyzing organization of text structures/features',
        'evaluating content from diverse media',
        "determining author's purpose and point of view",
        'comparing and contrasting multiple texts',
        "evaluating the author's claim",
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Comprehension in Informational Text': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        'demonstrating understanding of key ideas/details and drawing inferences',
        'determining the main idea/theme and summarizing',
        'describing interactions between people, ideas, and events',
        'determining the meaning of key vocabulary',
        'analyzing organization of text structures/features',
        'evaluating content from diverse media',
        "determining author's purpose and point of view",
        'comparing and contrasting multiple texts',
        "evaluating the author's claim",
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Foundations': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Writing': [
        'developing an idea for a written opinion',
        'identifying a topic to write about',
        'identifying a real or imagined event to write about',
        'formulating a written opinion with supporting reasons',
        'identifying a topic to write about with supportive facts',
        'writing about a real or imagined event in correct sequence using details',
        'understanding how to write an opinion text using valid reasons and evidence',
        'examining ideas and information to write informative/explanatory text',
        'understanding how to write a narrative about a real or imagined event',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Social Emotional Learning': [
        "being aware of and managing one's own emotions",
        "recognizing one's own personal traits and external supports",
        'demonstrating skills for achieving goals',
        'recognizing the feelings and perspectives of others',
        'recognizing group similarities and differences',
        'communicating and interacting effectively with others',
        'preventing and resolving interpersonal conflicts',
        'making ethical, safe, responsible decisions',
        'applying decision making skills in school situations',
        'contributing to the school and community',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Math': [
        'representing problems using objects, pictures, and symbols',
        'understanding and using properties',
        'performing multi digit operations accurately and fluently',
        'performing arithmetic accurately and fluently',
        'understanding and using place value',
        'recognizing and explaining patterns in arithmetic',
        'performing tasks involving measurement and data',
        'solving equations',
        'solving real world measurement problems',
        'representing and interpreting data',
        'understanding and working with fractions',
        'understanding and working with decimals',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ]
    },
    "68": {
      'Reading Comprehension in Literature': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        'demonstrating understanding of key ideas/details and drawing inferences',
        'determining the main idea/theme and summarizing',
        'describing interactions between people, ideas, and events',
        'determining the meaning of grade level vocabulary',
        'determining the meaning of words/phrases and analyzing effects of word choice',
        'analyzing organization of text structures/features',
        "determining author's purpose and point of view",
        'evaluating content from diverse media',
        "evaluating the author's claim",
        'comparing and contrasting multiple texts',
        'citing text evidence to support inferences',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Comprehension in Informational Text': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        'demonstrating understanding of key ideas/details and drawing inferences',
        'determining the main idea/theme and summarizing',
        'describing interactions between people, ideas, and events',
        'determining the meaning of grade level vocabulary',
        'determining the meaning of words/phrases and analyzing effects of word choice',
        'analyzing organization of text structures/features',
        "determining author's purpose and point of view",
        'evaluating content from diverse media',
        "evaluating the author's claim",
        'comparing and contrasting multiple texts',
        'citing text evidence to support inferences',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Foundations': [

      ],
      'Writing': [
        'developing an idea for a written opinion',
        'identifying a topic to write about',
        'identifying a real or imagined event to write about',
        'writing opinions using valid reasons and evidence',
        'examining ideas and information to write informative texts',
        'writing narratives about a real or imagined events',
        'writing arguments using valid reasons and evidence',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Social Emotional Learning': [
        "being aware of and managing one's own emotions",
        "recognizing one's own personal traits and external supports",
        'demonstrating skills for achieving goals',
        'recognizing the feelings and perspectives of others',
        'recognizing group similarities and differences',
        'communicating and interacting effectively with others',
        'preventing and resolving interpersonal conflicts',
        'making ethical, safe, responsible decisions',
        'applying decision making skills in school situations',
        'contributing to the school and community',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Math': [
        'working with ratios and proportions',
        'solving real world proportion problems',
        'performing operations with rational numbers',
        'working with irrational numbers',
        'using variables in expressions and equations',
        'working with radicals and integer exponents',
        'using equations and expressions to solve real life problems',
        'solving linear equations',
        'working with functions',
        'solving real world geometry problems',
        'drawing and describing geometrical figures',
        'understanding geometric relationships',
        'working with statistics and probability',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ]
    },
    "912": {
      'Reading Comprehension in Literature': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        'demonstrating understanding of key ideas/details and drawing inferences',
        'determining the main idea/theme and summarizing',
        'describing interactions between people, ideas, and events',
        'determining the meaning of words/phrases and analyzing effects of word choice',
        'analyzing organization of text structures/features',
        "determining author's purpose and point of view",
        'evaluating content from diverse media',
        "evaluating the author's claim",
        'comparing and contrasting multiple texts',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Comprehension in Informational Text': [
        'demonstrating phonics skills',
        'fluently reading regularly spelled words',
        'reading irregularly spelled words',
        'reading grade-level texts accurately and fluently',
        'demonstrating understanding of key ideas/details and drawing inferences',
        'determining the main idea/theme and summarizing',
        'describing interactions between people, ideas, and events',
        'determining the meaning of words/phrases and analyzing effects of word choice',
        'analyzing organization of text structures/features',
        "determining author's purpose and point of view",
        'evaluating content from diverse media',
        "evaluating the author's claim",
        'comparing and contrasting multiple texts',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Reading Foundations': [

      ],
      'Writing': [
        'developing an idea for a written opinion',
        'identifying a topic to write about',
        'identifying a real or imagined event to write about',
        'writing opinions using valid reasons and evidence',
        'writing arguments using valid reasons and evidence',
        'examining ideas and information to write informative texts',
        'writing narratives about a real or imagined events',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Social Emotional Learning': [
        "being aware of and managing one's own emotions",
        "recognizing one's own personal traits and external supports",
        'demonstrating skills for achieving goals',
        'recognizing the feelings and perspectives of others',
        'recognizing group similarities and differences',
        'communicating and interacting effectively with others',
        'preventing and resolving interpersonal conflicts',
        'making ethical, safe, responsible decisions',
        'contributing to the school and community',
        'applying decision making skills in school situations',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ],
      'Math': [
        'using properties of rational and irrational numbers',
        'reasoning quantitatively and using units to solve problems',
        'interpreting algebraic expressions',
        'solving problems by writing expressions in equivalent forms',
        'understanding and working with polynomials',
        'rewriting rational expressions',
        'creating equations that describe numbers or relationships',
        'explaining reasoning for solving equations',
        'solving equations and inequalities',
        'representing equations and inequalities graphically',
        "demonstrating persistence when working on tasks",
        "showing best effort during school activities",
        "having a commitment to learning",
        "being sensitive and supportive of others",
        "accepting individual differences",
        "following school rules",
      ]
    }
  }
}

export function getUploadedFileId(uploadedFile) {
  if (uploadedFile.hasOwnProperty("districtId") &&
    uploadedFile.hasOwnProperty("schoolId") &&
    uploadedFile.hasOwnProperty("teacherId") &&
    uploadedFile.hasOwnProperty("studentId") &&
    uploadedFile.hasOwnProperty("fileName")) {
    return uploadedFile.districtId + uploadedFile.schoolId + uploadedFile.teacherId + uploadedFile.studentId + uploadedFile.fileName
  } else {
    console.log("uploaded file missing key properties")
    return ""
  }
}

export function doesChatNotificationDictHaveNotifications(chatNotificationDict, currentlyViewingChatId) {
  if (!chatNotificationDict) {
    return false
  }

  let keys = Object.keys(chatNotificationDict)
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (key === 'initialField') {
      continue
    }
    else if (key === 'id') {
      continue
    }
    // will be unwritten soon by the ChatHome component,
    // don't show it as it will cause a flicker of being shown
    // and then quickly unwritten.
    else if (currentlyViewingChatId && key === currentlyViewingChatId) {
      continue
    }
    else if (chatNotificationDict[key]) {
      return true
    }
  }

  return false
}

export function getDistrictLogo(districtName, districtState) {
  let info = { src: '', mission: '', fontSize: 24 }
  if (districtState === 'South Carolina' && districtName === 'Clarendon') {
    info.src = '/district-logos/clarendonsc-logo.png'
    info.mission = `Unified as one, the mission of the Clarendon County
                    School District is to educate and empower students to
                    become productive and responsible citizens.`
    info.fontSize = 24
  } else if (districtState === 'Kentucky' || districtState === 'KY') {
    info.src = ''
    info.mission = ''
    info.fontSize = 50
  }
  // test district 
  else if (districtName === 'Ocean District Schools') {
    info.src = '/district-logos/clarendonsc-logo.png'
  }

  return info
}

export function formatNumberWithCommas(numberValue) {
  return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function chosenAnswersCorrectData(question) {
  // handles multiple answers later
  let correctAnswers = []
  let correct = 0
  for (let answer of question.choices) {
    if (answer.correct) {
      correctAnswers.push(answer.choice)
    }
  }

  for (let chosenAnswer of question.chosenAnswers) {
    if (correctAnswers.includes(chosenAnswer)) {
      correct += 1
    }
  }

  return { correct: correct, total: correctAnswers.length }
}

export function getChosenAnswersCorrectData(question, chosenAnswers) {
  // handles multiple answers later
  let correctAnswers = []
  let correct = 0
  for (let answer of question.choices) {
    if (answer.correct) {
      correctAnswers.push(answer.choice)
    }
  }

  for (let chosenAnswer of chosenAnswers) {
    if (correctAnswers.includes(chosenAnswer)) {
      correct += 1
    }
  }

  return { correct: correct, total: correctAnswers.length }
}

export function cleverReauth(cleverContinueURL) {
  return
}

export function cleverIsAuthed() {
  return new Promise((resolve, reject) => {
    reject()
  })
}

export function cleverGetStudents() {
  return new Promise((resolve, reject) => {
    reject()
  })
}

export function cleverStartQuiz(teacher, mode, sections, studentIds, fullQuiz) {
  // if mode is 'sections', sections is used and studentIds is []
  // if mode is 'students', sections is [] and studentIds is used
  if (!(teacher && mode && sections && studentIds && fullQuiz)) {
    return new Promise((resolve, reject) => {
      reject('missing parameters')
    })
  }

  // no clever auth needed, as it is using Dot It data
  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(true).then((idToken) => {
      const quiz = Object.assign({}, fullQuiz)
      quiz.questionIdx = 0
      quiz.chosenAnswers = {}
      quiz.chosenAnswersBaseQuiz = {}
      quiz.districtId = teacher.districtId
      quiz.schoolId = teacher.schoolId
      quiz.teacherId = teacher.id
      quiz.docs = JSON.stringify(quiz.docs)

      let headers = new Headers()

      headers.append('Authorization', 'Bearer ' + idToken)
      headers.append('Origin', 'https://my.dotit.app')
      headers.append('Content-Type', 'application/json')
      headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

      fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/clever-start-quiz", {
        method: "POST",
        body: JSON.stringify({
          teacher: {
            firstName: teacher.firstName,
            lastName: teacher.lastName,
            emailAddress: teacher.emailAddress,
            id: teacher.id,
            lowerCaseEmailAddress: teacher.hasOwnProperty('lowerCaseEmailAdress') ? teacher.lowerCaseEmailAddress : teacher.emailAddress.toLowerCase(),
            schoolId: teacher.schoolId,
            districtId: teacher.districtId,
          },
          mode: mode,
          sections: sections,
          studentIds: studentIds,
          quiz: quiz,
        }),
        mode: 'cors',
        headers: headers,
      })
        .then(res => res.json())
        .then(data => {
          console.log('assessment success', data)
          if (data.hasOwnProperty('message') && data.message === 'assessment created') {
            resolve(data)
          } else {
            reject('assessment not created')
          }
        })
        .catch(e => {
          console.log("assessment error", e)
          reject(e)
        })
    }).catch((e) => {
      console.log("assessment request error", e)
      reject(e)
    })
  })
}

export function getQuarterAndWeekFromNum(ind) {
  if (ind === 0) {
    return {
      quarter: 0,
      week: 0,
    }
  }
  let quarter = -1
  if (ind >= 1 && ind < 10) {
    quarter = 1
  } else if (ind >= 10 && ind < 19) {
    quarter = 2
  } else if (ind >= 19 && ind < 28) {
    quarter = 3
  } else if (ind >= 28 && ind < 37) {
    quarter = 4
  }
  let weekNum = ind % 9
  if (weekNum === 0) {
    weekNum = 9
  }

  return {
    quarter: quarter,
    week: weekNum,
  }
}

export function shuffleArray(inputArray) {
  let array = []
  for (let i = 0; i < inputArray.length; i++) {
    array.push(inputArray[i])
  }
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index
    let j = Math.floor(Math.random() * (i + 1));

    // Swap elements at i and j
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function shuffleBlock(array, start, end) {
  for (let i = end - 1; i >= start; i--) {
    let j = Math.floor(Math.random() * (i - start + 1)) + start;
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export function shuffleArrayWithGroups(inputArray) {
  let array = [];
  for (let i = 0; i < inputArray.length; i++) {
    array.push(inputArray[i]);
  }
  let groupedItems = new Map();
  let nonGroupedItems = [];

  for (let i = 0; i < array.length; i++) {
    let item = array[i];
    if (item.metadata && item.metadata.groupUuid) {
      if (!groupedItems.has(item.metadata.groupUuid)) {
        groupedItems.set(item.metadata.groupUuid, []);
      }
      groupedItems.get(item.metadata.groupUuid).push(item);
    } else {
      nonGroupedItems.push(item);
    }
  }

  // Shuffle blocks and individual items
  let allItems = [];
  groupedItems.forEach(function (groupBlock, groupUuid) {
    allItems.push({ type: 'group', items: groupBlock });
  });

  for (let i = 0; i < nonGroupedItems.length; i++) {
    allItems.push({ type: 'single', item: nonGroupedItems[i] });
  }

  for (let i = allItems.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    var temp = allItems[i];
    allItems[i] = allItems[j];
    allItems[j] = temp;
  }

  // Reassemble the final array
  let reassembledArray = [];
  for (let i = 0; i < allItems.length; i++) {
    let entry = allItems[i];
    if (entry.type === 'group') {
      shuffleBlock(entry.items, 0, entry.items.length);
      for (let j = 0; j < entry.items.length; j++) {
        reassembledArray.push(entry.items[j]);
      }
    } else {
      reassembledArray.push(entry.item);
    }
  }

  return reassembledArray;
}

export function getAssessmentDisplayName(assessment) {
  if (!assessment) {
    return 'Quiz'
  }

  if (assessment && assessment.hasOwnProperty('assessmentTitle')) {
    return assessment.assessmentTitle
  }

  // legacy
  let standardCategory = ''
  let standardName = ''
  try {
    standardCategory = assessment.quiz.standardCategory
    standardName = assessment.quiz.standardName
  } catch (e) {
    // fall through
  }

  if (assessment && assessment.assessmentCategory === "common_assessment") {
    return standardName + ' ' + standardCategory + ' Common Assessment'
  } else if (assessment && assessment.assessmentCategory === "benchmark") {
    return standardName + ' ' + standardCategory + ' Benchmark'
  }

  return 'Quiz'
}

export function getFullAssessmentDisplayName(assessment) {
  try {
    if (!assessment || !assessment.quiz) {
      return 'Quiz'
    }
    let assessmentDisplayName = getAssessmentDisplayName(assessment.quiz)
    if (assessment.quiz.hasOwnProperty('assessmentTitle')) {
      assessmentDisplayName = assessment.quiz.assessmentTitle
    } else if (assessment.quiz.assessmentCategory === 'quiz') {
      assessmentDisplayName = assessment.quiz.standard.standardCategory + ' ' + assessmentDisplayName + ': ' + assessment.quiz.standard.standard
    } else if (assessment.quiz.assessmentCategory === 'common_assessment' || assessment.quiz.assessmentCategory === 'benchmark') {
      if (assessment.quiz.hasOwnProperty('standards') && assessment.quiz.standards && assessment.quiz.standards.length > 0) {
        const firstStandard = assessment.quiz.standards[0]
        if (firstStandard.standardName.includes('Grade')) {
          assessmentDisplayName = firstStandard.standardName + ' ' + firstStandard.standardCategory + ' ' + assessmentDisplayName
        } else {
          assessmentDisplayName = firstStandard.standardCategory + ' ' + assessmentDisplayName
        }
      }
    }
    return assessmentDisplayName
  } catch (e) {
    console.log('error', e)
    return 'Quiz'
  }
}

export async function getCurrentTimestamp(userUID, state) {
  const currentTime = moment.utc();

  if (!state) {
    console.log('getCurrentTimestamp: no state provided')
    const timeZone = getTimeZoneByState('SC');
    return currentTime.tz(timeZone); // Convert to local time based on the state
  }

  // Get the timezone for the given state
  const timeZone = getTimeZoneByState(state);
  if (timeZone === 'Unknown') {
    console.log('getCurrentTimestamp: uknown timezone for state:', state)
    return currentTime.tz('America/New_York'); // Default to Eastern Time if the state is unknown
  }

  if (!userUID) {
    console.log('getCurrentTimestamp: no userUID provided')
    return currentTime.tz(timeZone); // Convert to local time based on the state
  }

  const docRef = db.collection(ColType.time).doc(userUID);

  // Set the server timestamp
  await docRef.set({
    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
  }, { merge: true });

  // Read back the data
  const doc = await docRef.get();

  if (doc.exists) {
    //console.log('getCurrentTimestamp: document found')
    const data = doc.data();
    const timestamp = data.timeStamp;
    const date = timestamp.toDate(); // Convert it to a JavaScript Date object
    const momentObj = moment(date).tz(timeZone); // Convert Date object to Moment object in local time

    return momentObj;
  } else {
    console.log('getCurrentTimestamp: no document found')
    // Return current time in local time based on the state if the document does not exist
    return currentTime.tz(timeZone);
  }
}

export function getSCBiology1Standards() {
  return {
    'B-LS1-1': true,
    'B-LS1-4': true,
    'B-LS1-5': true,
    'B-LS1-6': true,
    'B-LS1-7': true,
    'B-LS2-1': true,
    'B-LS2-5': true,
    'B-LS2-7': true,
    'B-LS3-2': true,
    'B-LS3-3': true,
    'B-LS4-1': true,
    'B-LS4-2': true,
    'B-LS4-4': true,
    'B-LS4-5': true,
  }
}

export function getTimeZoneByState(state) {
  const timeZoneMap = {
    'AK': 'America/Anchorage',
    'AL': 'America/Chicago',
    'AR': 'America/Chicago',
    'AZ': 'America/Phoenix',
    'CA': 'America/Los_Angeles',
    'CO': 'America/Denver',
    'CT': 'America/New_York',
    'DC': 'America/New_York',
    'DE': 'America/New_York',
    'FL': 'America/New_York', // Note: Parts of Florida are in Central Time
    'GA': 'America/New_York',
    'HI': 'Pacific/Honolulu',
    'IA': 'America/Chicago',
    'ID': 'America/Denver',
    'IL': 'America/Chicago',
    'IN': 'America/Indiana/Indianapolis',
    'KS': 'America/Chicago',
    'KY': 'America/New_York',
    'LA': 'America/Chicago',
    'MA': 'America/New_York',
    'MD': 'America/New_York',
    'ME': 'America/New_York',
    'MI': 'America/New_York',
    'MN': 'America/Chicago',
    'MO': 'America/Chicago',
    'MS': 'America/Chicago',
    'MT': 'America/Denver',
    'NC': 'America/New_York',
    'ND': 'America/Chicago',
    'NE': 'America/Chicago',
    'NH': 'America/New_York',
    'NJ': 'America/New_York',
    'NM': 'America/Denver',
    'NV': 'America/Los_Angeles',
    'NY': 'America/New_York',
    'OH': 'America/New_York',
    'OK': 'America/Chicago',
    'OR': 'America/Los_Angeles',
    'PA': 'America/New_York',
    'RI': 'America/New_York',
    'SC': 'America/New_York',
    'SD': 'America/Chicago',
    'TN': 'America/Chicago',
    'TX': 'America/Chicago', // Note: Parts of Texas are in Mountain Time
    'UT': 'America/Denver',
    'VA': 'America/New_York',
    'VT': 'America/New_York',
    'WA': 'America/Los_Angeles',
    'WI': 'America/Chicago',
    'WV': 'America/New_York',
    'WY': 'America/Denver'
  };

  return timeZoneMap[state] || 'Unknown';
}

export function addCommasToNumbersInString(str) {
  // If the input string contains commas, do nothing and return the original string
  if (str.includes(',')) {
    return str;
  }

  // Regex to match numbers, considering optional commas, decimals, and even scientific notation
  return str.replace(/\b\d+(\.\d+)?\b/g, (match) => {
    if (match.includes(',')) {
      return match;
    }
    // Split the integer and decimal parts
    const [integerPart, decimalPart] = match.split('.');
    // Add commas only to the integer part
    const formattedInteger = parseInt(integerPart).toLocaleString('en-US');
    // Rejoin with the decimal part if it exists
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  });
}


export function addCommasToNumbersInStringLatex(str) {
  // If the input string contains commas, do nothing and return the original string
  if (str.includes(',')) {
    return str;
  }

  // Regex to match numbers, considering optional commas, decimals
  return str.replace(/\b\d+(\.\d+)?\b/g, (match) => {
    if (match.includes(',')) {
      return match;
    }
    // Split the integer and decimal parts
    const [integerPart, decimalPart] = match.split('.');

    // Add commas only to the integer part and insert negative spaces after them
    const formattedInteger = parseInt(integerPart)
      .toLocaleString('en-US')
      .replace(/,/g, ',\\!');

    // Rejoin with the decimal part if it exists
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  });
}

export function getColorGradient(percent) {
  if (typeof percent !== 'number') {
    console.log('getColorGradient: percent is not a number')
    return '#ffffff';
  }
  const lerp = (a, b, t) => a + (b - a) * t;

  const getInterpolatedColor = (color1, color2, t) => {
    const [r1, g1, b1] = color1.match(/\w\w/g).map((c) => parseInt(c, 16));
    const [r2, g2, b2] = color2.match(/\w\w/g).map((c) => parseInt(c, 16));

    const r = Math.round(lerp(r1, r2, t));
    const g = Math.round(lerp(g1, g2, t));
    const b = Math.round(lerp(b1, b2, t));

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  if (percent > 60) {
    return getInterpolatedColor('#d9f7be', '#95de64', (percent - 60) / 40); // Dark green to light green
  } else if (percent > 30) {
    return getInterpolatedColor('#fff1b8', '#fdd766', (percent - 30) / 20); // Dark yellow to light yellow
  } else {
    return getInterpolatedColor('#ff7875', '#ffccc7', percent / 30); // Dark red to light red, spans 0 to 30
  }
}

export function chunkArray(array, size) {
  if (!array) {
    return [];
  }
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

export function getUUIDV4() {
  if (typeof crypto !== "undefined" && typeof crypto.randomUUID === "function") {
    return crypto.randomUUID();
  } else {
    console.log('Cannot generate a valid UUID v4')
    // fallback in case crypto.randomUUID is not available
    // This function generates a UUID v4 using the method described in RFC4122
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

export function getStandardSettings() {
  return new Promise((resolve, reject) => {
    db.collection(ColType.standardSettings).doc('standardSettings').get().then((doc) => {
      resolve(doc.data())
    })
      .catch((e) => {
        reject(e)
      })
  })
}

export function manualCheckQuestionDoc(doc, verbose = false) {
  try {
    if (!doc) {
      return false
    }

    if (typeof doc.metadata.dokLevel !== 'number') {
      if (verbose) {
        console.log('manualCheckQuestionDoc failed: dokLevel is not a number');
      }
      return false;
    }

    for (const assessment of doc.components) {
      const numAnswers = assessment.answers.length;
      if (numAnswers !== 4) {
        if (verbose) {
          console.log('manualCheckQuestionDoc failed: number of answers is not 4');
        }
        return false;
      }

      // Check IDs are 0, 1, 2, 3
      // not 1, 2, 3, 4 as they are 0-indexed before getting to client side
      for (let i = 0; i < numAnswers; i++) {
        if (assessment.answers[i].id !== i) {
          if (verbose) {
            console.log('manualCheckQuestionDoc failed: answer IDs are not 0, 1, 2, 3');
          }
          return false;
        }
      }

      // Check there is exactly one true answer
      const numTrue = assessment.answers.reduce((count, answer) => count + (answer.correct ? 1 : 0), 0);
      if (numTrue !== 1) {
        if (verbose) {
          console.log('manualCheckQuestionDoc failed: number of true answers is not 1');
        }
        return false;
      }

      // Check if any two answers are the same
      for (let i = 0; i < numAnswers; i++) {
        for (let j = i + 1; j < numAnswers; j++) {
          if (assessment.answers[i].choice === assessment.answers[j].choice) {
            if (verbose) {
              console.log('manualCheckQuestionDoc failed: two answers are the same');
            }
            return false;
          }
        }
      }
    }

    return true;
  } catch (e) {
    console.log('manualCheckQuestionDoc error', e)
    return false
  }
}

export function manualCheckAssessment(quiz, verbose = false) {
  if (!quiz) {
    if (verbose) {
      console.log('manualCheckAssessment failed: quiz is null');
    }
    return false
  }

  for (const doc of quiz.docs) {
    if (!manualCheckQuestionDoc(doc, verbose)) {
      return false;
    }
  }

  return true;
}

export function getQuestionStatusId(districtId, questionUUID) {
  return districtId + '-' + questionUUID
}

export function setQuestionStatus(districtId, questionUUID, status) {
  console.log('setting question status', districtId, questionUUID, status)
  db.collection(ColType.questionStatus)
    .doc(getQuestionStatusId(districtId, questionUUID))
    .set({
      districtId: districtId,
      questionUUID: questionUUID,
      status: status,
      timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      console.log('question status added')
    })
    .catch((e) => {
      console.log('error', e)
    })
}

export function getFileNameAndExtension(fullFileName) {
  if (!fullFileName) {
    return { fileName: '', extension: '' };
  }
  const lastDotIndex = fullFileName.lastIndexOf('.');

  // Extracting the file name without extension
  const fileName = fullFileName.substring(0, lastDotIndex);

  // Extracting the extension
  const extension = fullFileName.substring(lastDotIndex + 1);

  return { fileName: fileName, extension: extension };
}

export function isTestingTimeRangeExpired(assessment, serverMoment) {
  return false;
  /*
  if (!(assessment && serverMoment)) {
    return false;
  }

  let assessmentCategory = ''
  if (assessment) {
    assessmentCategory = assessment.quiz.assessmentCategory
    if (assessment.hasOwnProperty('quiz') &&
      assessment.quiz &&
      assessment.quiz.hasOwnProperty('assessmentTitle') &&
      assessment.quiz.assessmentTitle) {
      if (assessment.quiz.assessmentTitle.toLowerCase().includes('common assessment')) {
        assessmentCategory = 'common_assessment'
      } else if (assessment.quiz.assessmentTitle.toLowerCase().includes('benchmark')) {
        assessmentCategory = 'benchmark'
      } else if (assessment.quiz.assessmentTitle.toLowerCase().includes('quiz')) {
        assessmentCategory = 'quiz'
      }
    }
  }

  if (assessment &&
    assessment.hasOwnProperty('timeStamp') &&
    assessment.timeStamp &&
    assessment.timeStamp.hasOwnProperty('seconds')) {
    let assessmentMoment = moment.utc(assessment.timeStamp.seconds * 1000)
    // quiz, common_assessment expire in 1 week
    let assessmentMomentEnd = assessmentMoment.clone().add(1, 'week')
    // benchmarks expire in 3 months
    if (assessmentCategory === 'benchmark') {
      assessmentMomentEnd = assessmentMoment.clone().add(3, 'months')
    }
    if (serverMoment) {
      if (serverMoment.isAfter(assessmentMomentEnd)) {
        return true;
      }
    }
  }

  return false;
  */
}

export function getDOKLevelFromGrade(finalGrade) {
  if (finalGrade >= 70) {
    return 3;
  } else if (finalGrade >= 40) {
    return 2;
  }

  return 1;
}

export function getYearStart() {
  return new Date('2024-08-01T00:00:00Z');
}

export function getCurrentYear() {
  return '2024';
}