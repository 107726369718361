import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import { firebase } from '../firebase/Firebase'
import { getInitials, getQueryStringParam } from '.././Util'
import { Layout, Menu, Avatar, Popover, message, Icon, Badge } from 'antd'
const { Header } = Layout

const errorMessage = (description) => {
  message.error(description)
}

class AdminHeader extends Component {
  state = {
    newPopoverVisible: false,
    accountPopoverVisible: false,
    searchValue: '',
    adminId: '',
    selectedOptionId: null,
    clearPrevStats: false,
  }

  componentDidMount() {
    let selectedId = getQueryStringParam('s')
    if (!selectedId && this.props.adminBaseProps.district) {
      this.setState({
        selectedOptionId: this.props.adminBaseProps.district.id
      })
    } else if (selectedId) {
      this.setState({
        selectedOptionId: selectedId
      })
    }
  }

  componentWillReceiveProps(newProps) {
    let selectedId = getQueryStringParam('s')
    if (!selectedId && this.props.adminBaseProps.district) {
      this.setState({
        selectedOptionId: this.props.adminBaseProps.district.id
      })
    } else if (selectedId) {
      this.setState({
        selectedOptionId: selectedId
      })
    }

    // on url change set popovers visible to false
    this.setState({
      newPopoverVisible: false,
      accountPopoverVisible: false,
    })
  }

  newVisibleChange = (visible) => {
    this.setState({ newPopoverVisible: visible })
  }

  accountVisibleChange = (visible) => {
    this.setState({ accountPopoverVisible: visible })
  }

  signOut = () => {
    firebase.auth().signOut().then(() => {
      console.log('Signed Out')
      //sending users to the sign in page after signing out for now.
      if (this.props.adminBaseProps &&
        this.props.adminBaseProps.hasOwnProperty('demoToken') &&
        this.props.adminBaseProps.demoToken) {
        this.props.history.push(
          {
            pathname: '/demo',
            search: '?dt=' + this.props.adminBaseProps.demoToken
          }
        )
      } else if (this.props.adminBaseProps &&
        this.props.adminBaseProps.hasOwnProperty('district') &&
        this.props.adminBaseProps.district &&
        (this.props.adminBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
          this.props.adminBaseProps.district.id === '64e3cb7826043cd12a0f829e')) {
        this.props.history.push(
          {
            pathname: '/demo-sign-in'
          }
        )
      } else if (this.props.person &&
        this.props.person.hasOwnProperty('districtId') &&
        this.props.person.districtId === 'vYTvkDwoLx19NkA6dJO8') {
        this.props.history.push(
          {
            pathname: '/beta/sign-in/'
          }
        )
      } else {
        this.props.history.push(
          {
            pathname: '/sign-in/'
          }
        )
      }
    }, function (error) {
      console.error('Sign Out Error', error)
      errorMessage("There was an error signing you out. Please try again.")
    })
  }

  onChange = (value) => {
    console.log(`selected ${value}`)
  }

  onSelect = (value, option) => {
    console.log('on select', value, option)
    console.log('/teacher/student/' +
      this.props.person.id + '?student=' +
      option.key)

    this.props.history.push(
      {
        pathname: '/admin/school-summary/' +
          this.props.person.id + '?district=' +
          this.props.person.districtId + '&school=' +
          option.key
      }
    )
  }

  render() {
    return (
      <div>
        {this.props.person != null &&
          Object.keys(this.props.person).length !== 0 &&
          this.props.person.constructor === Object ?
          <Header
            className={(this.props.hasOwnProperty("positionNotFixed") &&
              this.props.positionNotFixed ? '' : ' position-fixed' +
            (this.props.readOnly ? ' t-80' : ''))}
            style={{
              padding: 0,
              textAlign: 'left',
              width: '100%',
              zIndex: 5,
              borderBottom: '1px solid #f1f1f1'
            }}
          >
            <div className='p-lr-20-important'>
              <Menu
                selectedKeys={[this.props.selectedKey]}
                mode="horizontal"
                className="font-16 no-border-bottom flex flex-row background-fff"
                style={{ lineHeight: '80px' }}
              >
                <Menu.Item key="admin-logo" className="inline-flex flex-v-center menu-no-bb mr-3 pl-0">
                  <Link to={'/'} style={{ marginTop: '-3px' }}>
                    <img src='/dotitlogo.png' alt='logo' height='50' />
                  </Link>
                </Menu.Item>


                <Menu.Item key="pacing-guide" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/admin/pacing-guide/guide/' + this.props.person.id}
                    className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'guide' || this.props.selectedKey === 'lesson-plan' ? ' text-cyan' : '')}
                  >
                    <Icon type="read" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Pacing Guide</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="assessments" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/admin/assessments/' + this.props.person.id}
                    className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'assessments' ? ' text-cyan' : '')}
                  >
                    {this.props.adminBaseProps &&
                      this.props.adminBaseProps.hasOwnProperty('districtSettings') &&
                      this.props.adminBaseProps.districtSettings &&
                      this.props.adminBaseProps.districtSettings.hasOwnProperty('canActivelyReview') &&
                      this.props.adminBaseProps.districtSettings.canActivelyReview &&
                      Object.values(this.props.adminBaseProps.districtSettings.canActivelyReview).filter(value => value === true).length > 0 ?
                      <Badge count={'Review'} style={{ backgroundColor: '#FF5F34' }}>
                        <div>
                          <Icon type="dot-chart" className={"va-middle " +
                            "mr-0 br-50 p-1 font-24"} />
                          <span className="va-minus-4 font-20">Common Assessments</span>
                        </div>
                      </Badge>
                      :
                      <div>
                        <Icon type="dot-chart" className={"va-middle " +
                          "mr-0 br-50 p-1 font-24"} />
                        <span className="va-minus-4 font-20">Common Assessments</span>
                      </div>
                    }
                  </Link>
                </Menu.Item>

                {/*(this.props.adminBaseProps &&
                  this.props.adminBaseProps.hasOwnProperty('district') &&
                  this.props.adminBaseProps.district &&
                  (this.props.adminBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                    this.props.adminBaseProps.district.id === '64e3cb7826043cd12a0f829e')) ?
                  <Menu.Item key="dashboard" className="font-500 inline-flex flex-center p-0">
                    <Link
                      to={'/admin/admin-dashboard/' + this.props.person.id}
                      className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                        (this.props.selectedKey === 'admin-dashboard' ? ' text-cyan' : '')}
                    >
                      <Icon type="dashboard" className={"va-middle " +
                        "mr-0 br-50 p-1 font-24"} />
                      <span className="va-minus-4 font-20">Dashboard</span>
                    </Link>
                </Menu.Item> : ''*/}


                <Menu.Item key="home" className="font-500 inline-flex flex-center p-0">
                  <Link
                    to={'/admin/admin-home/' + this.props.person.id}
                    className={"p-lr-20-important onboard-students-tab flex-h-center font-bold" +
                      (this.props.selectedKey === 'admin-home' ? ' text-cyan' : '')}
                  >
                    <Icon type="home" className={"va-middle " +
                      "mr-0 br-50 p-1 font-24"} />
                    <span className="va-minus-4 font-20">Schools</span>
                  </Link>
                </Menu.Item>


                {/* 
<Menu.Item key="admin-schools" className="font-500 inline-flex flex-center">
                <Tooltip title="Find student information" placement="bottom" mouseEnterDelay={.75}>
                  <Link
                    to={'/admin/admin-schools/' + this.props.person.id}>
                    <Icon type="user" className={"font-24 va-middle flex-h-center " +
                      "mr-0 br-50 border p-1 font-30 ant-btn-primary"} />
                  </Link>
                </Tooltip>
              </Menu.Item>
              */}


                {/* <Menu.Item key="district-summary" className="font-500 inline-flex flex-center">
                <Tooltip title="District summary" placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/admin/district-summary/' + this.props.person.id}>
                  <Icon type="bar-chart" className={"font-24 va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 font-30 ant-btn-primary"} />
                </Link>
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="schools" className="font-500 inline-flex flex-center">
                <Tooltip title="School summaries" 
                placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/admin/schools/' + this.props.person.id}>
                  <img src='/school-icon-fff.svg'
                  width={49}
                  height={49} 
                  style={{fill: 'white'}}
                  className={"va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 ant-btn-primary"} 
                  alt='schools'
                  />
                </Link>
                </Tooltip>
              </Menu.Item>*/}
                {/*<Menu.Item key="add-person" className="font-500 inline-flex flex-center">
                <Tooltip title="Add personnel to district" placement="bottom" mouseEnterDelay={.75}>
                <Link
                  to={'/admin/add-person/' + this.props.person.id}>
                  <Icon type="plus" className={"font-24 va-middle flex-h-center " +
                  "mr-0 br-50 border p-1 font-30 ant-btn-primary"} />
                </Link>
                </Tooltip>
          </Menu.Item>*/}

                {/*<Menu.Item key="search" className="inline-flex flex-v-center menu-no-bb ml-auto">
                <Select
                  showSearch
                  className="ant-select-very-large cursor-text br-100"
                  placeholder={'Search schools'}
                  showArrow={false}
                  onChange={this.onChange}
                  onSelect={this.onSelect}
                  style={{ width: '350px' }}
                >
                  {this.props.schools && this.props.schools.map((school, index) => {
                    return <Option
                      key={school.id}
                      value={school.schoolName}
                      title={school.schoolName}
                    >
                      {school.schoolName}
                    </Option>
                  })
                  }
                </Select>
                <Icon type="search" className="font-16 select-suffix" />
                </Menu.Item>*/}

                <Menu.Item key="teacher-sign-out"
                  className="inline-flex flex-v-center menu-no-bb ml-auto pr-0">
                  <Popover
                    visible={this.state.accountPopoverVisible}
                    onVisibleChange={this.accountVisibleChange}
                    placement="bottomLeft"
                    title={
                      <div className="pt-1 pb-1">
                        <Avatar
                          size="large"
                          style={{ backgroundColor: '#d3adf7' }}
                          className="avatar-vlg"
                        >
                          <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                        </Avatar>
                        <span className="font-20 ml-1 font-bold vertical-align-middle">
                          {this.props.person.firstName + " " + this.props.person.lastName}
                        </span>
                      </div>
                    } content={
                      <div className="p-0 border-right-none negate-popover-padding">
                        <div className="ant-menu-item p-0" onClick={this.signOut}>
                          <span className="font-16 p-2 block font-bold">
                            <span className="va-minus-1 font-20">Sign out</span>
                          </span>
                        </div>
                      </div>
                    } trigger="click">
                    <span className="h-80px inline-flex flex-v-center cursor-pointer">
                      <span className="inline-block-important">
                        <Avatar
                          size="large"
                          style={{ backgroundColor: '#d3adf7' }}
                          className="avatar-vlg"
                        >
                          <span className="font-bold font-20 font-black">{getInitials(this.props.person)}</span>
                        </Avatar>
                      </span>
                    </span>
                  </Popover>
                </Menu.Item>

              </Menu>
            </div>

          </Header>
          : ''}
      </div>
    )
  }
}

export default AdminHeader